// Button category
@mixin onlyIcon($color, $backgroundColor, $hasBorderLine: false, $borderColor) {
  border: none;
  padding: 8px;
  background: $backgroundColor;
  border-radius: 200px 200px 200px 0px;
  display: inline-block;
  text-align: center;
  color: $color;
  margin-bottom: 12px !important;
  position: relative;
  @if $hasBorderLine {
    border: 2px solid $borderColor;
  }

  & .mat-icon {
    height: 20px;
    width: 20px;
    font-size: 20px;
  }

  & img {
    width: 20px;
    height: 20px;
    margin-bottom: 7px;
  }
}

@mixin onlyText($color, $backgroundColor, $hasBorderLine, $borderColor) {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  color: $color;
  background: $backgroundColor;
  border-radius: 16px 16px 16px 0px;
  padding: 10px 18px;
  border: 0;
  text-align: center;
  outline: 0;
  cursor: pointer;
  min-width: 96px;
  
  @if $hasBorderLine {
    border: 2px solid $borderColor;
  }
}

@mixin IconAndText($color, $backgroundColor, $hasBorderLine, $borderColor) {
  display: flex;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  color: $color;
  background: $backgroundColor;
  border-radius: 16px 16px 16px 0px;
  padding: 11px 18px;
  border: 0;
  text-align: center;
  outline: 0;
  cursor: pointer;
  min-width: 96px;

  @if $hasBorderLine {
    border: 2px solid $borderColor;
  }

  & .mat-icon {
    font-style: normal;
    font-weight: bold;
    height: 14px;
    width: 14px;
    font-size: 14px;
    margin: 0px 2px;
  }
}

// Button event
@mixin btnEvent($hoverColor, $hoverBackgroundColor, $focusColor, $focusBackgroundColor, $disableColor, $disableBackgroundColor, $hasBorderLine, $borderColor) {
  margin: 0.25rem 0.5rem 0;

  &:hover {
    outline: none;
    box-shadow: none;
    color: $hoverColor;
    background-color: $hoverBackgroundColor;
    -webkit-transform: translate(-2px, -2px);
    -ms-transform: translate(-2px, -2px);
    transform: translate(-2px, -2px);
  }


  &:focus:not(:focus-visible) {
    outline: 0;
    box-shadow: none;
  }
  
  &:focus:not(:hover) {
    color: $focusColor;
    background-color: $focusBackgroundColor;
  }

  &:disabled {
    color: $disableColor;
    background-color: $disableBackgroundColor;
    @if $hasBorderLine {
      border: 2px solid $disableColor;
    }
  }
}

// Set button style based on their specifications
@mixin setButtonStyle($color, $backgroundColor, $hasBorderLine, $borderColor, $hoverColor, $hoverBackgroundColor, $focusColor, $focusBackgroundColor, $disableColor, $disableBackgroundColor) {
  &-icon {
    width: 36px;
    height: 36px;
    @include onlyIcon($color, $backgroundColor, $hasBorderLine, $borderColor);
    @include btnEvent($hoverColor, $hoverBackgroundColor, $focusColor, $focusBackgroundColor, $disableColor, $disableBackgroundColor, $hasBorderLine, $borderColor);
  }

  &-text {
    // width: 76px;
    height: 36px;
    @include onlyText($color, $backgroundColor, $hasBorderLine, $borderColor);
    @include btnEvent($hoverColor, $hoverBackgroundColor, $focusColor, $focusBackgroundColor, $disableColor, $disableBackgroundColor, $hasBorderLine, $borderColor);
  }

  &-iconText {
    // width: 94px;
    height: 36px;
    @include IconAndText($color, $backgroundColor, $hasBorderLine, $borderColor);
    @include btnEvent($hoverColor, $hoverBackgroundColor, $focusColor, $focusBackgroundColor, $disableColor, $disableBackgroundColor, $hasBorderLine, $borderColor);
  }
}

.accent {
  &-filled {
    &-md-btn {
      @include setButtonStyle(#ffffff, #54D072, false, transparent, #ffffff, #54D072, #ffffff, #54D072, #B3C0C8, #E6EAED);
    }
  }

  &-outline {
    &-md-btn {
      @include setButtonStyle(#54D072, transparent, true, #54D072, #ffffff, #54D072, #54D072, transparent, #CCD5DA, transparent);
    }
  }

  &-text {
    &-md-btn {
      @include setButtonStyle(#54D072, transparent, false, transparent, #ffffff, #54D072, #54D072, transparent, #CCD5DA, transparent);
    }
  }
}

.mono-dark {
    &-filled {
      &-md-btn {
        @include setButtonStyle(#FFFFFF, #8096A2, false, #8096A2, #FFFFFF, #66808F, #FFFFFF, #8096A2, #B3C0C8, #E6EAED);
      }
    }

    &-outline {
      &-md-btn {
        @include setButtonStyle(#8096A2, transparent, true, #8096A2, #FFFFFF, #8096A2, #8096A2, transparent, #CCD5DA, transparent);
      }
    }

    &-text {
      &-md-btn {
        @include setButtonStyle(#8096A2, transparent, false, transparent, #8096A2, #E6EAED, #8096A2, #E6EAED, #CCD5DA, transparent);
      }
    }
}

.error {
  &-filled {
    &-md-btn {
      @include setButtonStyle(#FFFFFF, #FF5854, false, #D11B17, #FFFFFF, #D11B17, #FFFFFF, #FF5854, #99ABB5, #E6EAED);
    }
  }

  &-outline {
    &-md-btn {
      @include setButtonStyle(#FF5854, transparent, true, #FF5854, #FFFFFF, #FF5854, #FF5854, transparent, #CCD5DA, transparent);
    }
  }

  &-text {
    &-md-btn {
      @include setButtonStyle(#FF5854, transparent, false, transparent, #FF5854, #FFEFEE, #FF5854, #FFEFEE, #CCD5DA, transparent);
    }
  }
}
.white {
  &-filled {
    &-md-btn {
      @include setButtonStyle(#8096A2, #FFFFFF, false, #8096A2, #8096A2, #FFFFFF, #FFFFFF, #FFFFFF, #FFFFFF, #E6EAED);
    }
  }

  &-outline {
    &-md-btn {
      @include setButtonStyle(#8096A2, transparent, true, #8096A2, #8096A2, #8096A2, #8096A2, transparent, #FFFFFF, transparent);
    }
  }

  &-text {
    &-md-btn {
      @include setButtonStyle(#8096A2, transparent, false, #8096A2, #8096A2, #8096A2, #8096A2, #8096A2, #8096A2, transparent);
    }
  }
}