/*Googel Fonts  */
// @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700");
@import "~font-awesome/css/font-awesome.css";
// @import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,800");
@import "./scss/button.scss";
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;1,300;1,500&display=swap');

body, html {
  background-color: #ffffff !important;
  -webkit-font-smoothing: antialiased !important;
  font-family: 'Poppins', sans-serif !important;
  color: rgba(0, 44, 69, 0.9);
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
a {
  color: #04c3cb;
  text-decoration: none;
}
.font-normal{
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.001em;
    color: rgba(0, 44, 69, 0.8);
    font-family: 'Poppins', sans-serif !important;
}
/*For loader*/
.overlay {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(255, 255, 255, 0.6);
  z-index: 999999;
}
.custom-overlay{
  background: rgba(255, 255, 255, 0.6);
  z-index: 9;
}
.overlay__inner {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;  
  //border-radius: 20px;
}
.overlay__content {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
//.loadImg { width: 32px; height: 32px; display: inline-block; border-width: 4px; border-color: #5cc4cb; border-top-color: #fff; animation: spin 1s infinite linear; border-radius: 100%; border-style: solid;}
.loadImg {
  // background: url('assets/images/loading.gif');
  // background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  display: block;
  background-position: center;
  border-radius: 50%;
  border: 4.2px solid;
  border-color: #54D072;
  border-right-color: #54D072;
  animation: spinner-d3wgkg 1s infinite linear;

}
// .spinner {
//   font-size: 16px;
// }
@keyframes spinner-d3wgkg {
  to {
    transform: rotate(1turn);
  }
}
.col-md-offset-3 {
  margin-left: 25%;
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
body .btn-info {
  background-color: #4bbce8;
  border-color: #4bbce8;
  color: #ffffff !important;
}
body .btn-info:hover {
  background-color: #4bbce8;
  border-color: #4bbce8;
  color: #ffffff !important;
}
.card.small .onlyForDesktop {
  display: none;
}
.w-20 {
  width: 20%;
}
.w-25 {
  width: 25%;
}
.w-30 {
  width: 30%;
}
.border-btm {
  border-bottom: 1px solid #dde3eb;
}
.card.small {
  font-size: 100%;
}
.btn.btn-outline-custom {
  color: #384253;
  border-radius: 8px;
  background: #fff;
  border: 1px solid #dde3eb;
  padding: 9px 12px;
  font-size: 16px;
  font-weight: 500;
}
.btn.btn-outline-custom:hover {
  color: #4bbce8;
}
body .badge {
  min-width: 52px;
  border-radius: 12px;
  height: 22px;
  line-height: 14px;
}
.pointer {
  cursor: pointer;
}
body a {
  color: #4bbce8;
  text-decoration: none;
  background-color: transparent;
}
body a:hover {
  text-decoration: none;
}
[aria-sort="ascending"]
  .mat-sort-header-container.mat-sort-header-sorted
  .mat-sort-header-arrow {
  opacity: 1 !important;
  transform: translateY(0) !important;
  color: #2e394a;
}
[aria-sort="descending"]
  .mat-sort-header-container.mat-sort-header-sorted
  .mat-sort-header-arrow {
  opacity: 1 !important;
  transform: translateY(0) !important;
  color: #2e394a;
}
.table-bordered thead th .mat-sort-header-container .mat-sort-header-arrow {
  opacity: 0.54 !important;
  transform: translateY(0) !important;
}
[aria-sort="ascending"]
  .mat-sort-header-container.mat-sort-header-sorted
  .mat-sort-header-arrow
  .mat-sort-header-indicator
  .mat-sort-header-pointer-left {
  transform: rotate(-45deg) !important;
}
[aria-sort="ascending"]
  .mat-sort-header-container.mat-sort-header-sorted
  .mat-sort-header-arrow
  .mat-sort-header-indicator
  .mat-sort-header-pointer-right {
  transform: rotate(45deg) !important;
}
[aria-sort="descending"] .mat-sort-header-indicator {
  transform: translateY(10px) !important;
}
[aria-sort="descending"]
  .mat-sort-header-container.mat-sort-header-sorted
  .mat-sort-header-arrow
  .mat-sort-header-indicator
  .mat-sort-header-pointer-left {
  transform: rotate(45deg) !important;
}
[aria-sort="descending"]
  .mat-sort-header-container.mat-sort-header-sorted
  .mat-sort-header-arrow
  .mat-sort-header-indicator
  .mat-sort-header-pointer-right {
  transform: rotate(-45deg) !important;
}
.mat-sort-header-indicator {
  transform: translateY(0px) !important;
}
.mat-sort-header-pointer-left,
.mat-sort-header-pointer-right {
  transform: rotate(45deg) !important;
}
.mat-sort-header-pointer-left,
.mat-sort-header-pointer-left {
  transform: rotate(-45deg) !important;
}

.card-body-box.testWrapper .table thead th {
  white-space: nowrap;
  font-size: 14px;
}
.card-body-box.testWrapper .table tbody tr td:nth-child(4),
.card-body-box.testWrapper .table tbody tr td:nth-child(5),
.card-body-box.testWrapper .table tbody tr td:nth-child(6) {
  text-align: center;
}

.tdl-holder li:after,
.tdl-holder li:before {
  content: "\f142";
  top: 24px;
  position: absolute;
  font: normal normal normal 14px/1 FontAwesome;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  z-index: 99;
  color: #99abb4;
}

.tdl-holder li:after {
  left: 10px;
}

.tdl-holder li:before {
  left: 14px;
}

.tdl-holder li span {
  margin-left: 30px;
  vertical-align: middle;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.tdl-holder label {
  cursor: pointer;
  display: block;
  line-height: 50px;
  padding: 0 15px;
  padding-left: 30px;
  position: relative;
  margin: 0 !important;
  background: #fafafa;
  color: #99abb4;
  font-size: 15px;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.tdl-holder label:hover {
  background-color: rgba(153, 171, 180, 0.1);
}

.tdl-holder label a {
  border-radius: 50%;
  color: rgba(153, 171, 180, 0.5);
  float: right;
  line-height: normal;
  margin-top: 15px;
  text-align: center;
  text-decoration: none;
  height: 16px;
  width: 20px;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.tdl-holder label a:hover {
  color: rgba(153, 171, 180, 0.8);
}

.tdl-holder input[type="checkbox"] {
  cursor: pointer;
  opacity: 0;
  position: absolute;
}

.tdl-holder input[type="checkbox"] + i {
  border: 1px solid rgba(153, 171, 180, 0.3);
  display: block;
  height: 18px;
  position: absolute;
  top: 15px;
  width: 18px;
  z-index: 1;
}

.tdl-holder input[type="checkbox"]:checked + i::after {
  content: "\e64c";
  font-family: "themify";
  display: block;
  color: rgba(153, 171, 180, 0.5);
  left: 0;
  position: absolute;
  top: -16px;
  z-index: 2;
}

.tdl-holder input[type="checkbox"]:checked ~ span {
  text-decoration: line-through;
}

.tdl-holder input[type="text"] {
  height: 60px;
  margin-top: 20px;
  font-size: 14px;
}

.transition {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

p {
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  line-height: 22px;
  color: #212529;
}

p:focus {
  border: none;
  outline: 0;
}

button {
  text-decoration: none;
  outline: none !important;
  color: #878787;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
h5 {
  font-size: 1em;
}
ul,
ol {
  padding-left: 0;
}
.btn:focus,
button:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline: 0;
}
.btn.disabled,
.btn:disabled {
  cursor: not-allowed;
}
.disabled {
  cursor: not-allowed;
  pointer-events: none;
}
img {
  max-width: 100%;
}
.modal-header {
  display: block;
}

.mb-0 {
  margin-bottom: 0;
}

.pb-0 {
  padding-bottom: 0;
}

.ov-h {
  overflow: hidden;
}

.btn,
button,
input,
textarea {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.progress-thin {
  border-radius: 0;
  height: 5px;
}

canvas {
  max-width: 100%;
}

.btn {
  border-radius: 3px;
}

.text-muted {
  font-size: 14px;
}

.text-muted.muted-black {
  color: #333648 !important;
  font-weight: 600;
}

.progress-box {
  margin-bottom: 30px;
}

.progress-box .por-title {
  font-size: 1em;
  font-weight: 600;
  color: #868e96;
  padding-bottom: 5px;
}

.progress-box .por-txt {
  font-size: 13px;
  padding-bottom: 5px;
}

.order-table {
  position: relative;
}

.order-table:after,
.order-table:before {
  content: "";
  position: absolute;
  top: 0px;
  height: 37px;
  width: 10px;
  background: #e8e9ef;
}

.order-table:after {
  right: -1px;
}

.order-table:before {
  left: -1px;
}

.order-table tr th {
  background: #e8e9ef;
}

.order-table tr td:last-child,
.order-table tr th:last-child {
  text-align: right;
}

.order-table tr:last-child td {
  border: none;
}
.badge.badge-complete,
.badge.badge-pending {
  padding: 4px 6px;
}
.badge {
  color: white;
  padding: 10px;
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: 0.01em;
}

.badge-complete {
  background-color: #04CB9B;
  border-color: #04CB9B;
}
.badge-pending {
  background-color: #CCD5DA;
  border-color: #CCD5DA;
  color: #002C45;
  opacity: 0.7;
}

/* Main Styles */
body .navbar {
  padding: 0;
}
aside.left-panel {
  background: #fff;
  height: 100vh;
  padding: 0;
  vertical-align: top;
  width: 280px;
  -webkit-box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
  box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
  position: fixed;
  left: 0;
  bottom: 0;
  top: 55px;
  z-index: 999;
}

aside.left-panel:hover {
  overflow-x: scroll;
}

.open aside.left-panel:hover {
  overflow-x: inherit;
}

.small-device .right-panel {
  margin-left: 83px;
}

.navbar {
  background: #fff;
  border-radius: 0;
  border: none;
  display: inline-block;
  margin: 0;
  padding: 0;
  vertical-align: top;
}

.navbar .main-menu {
  float: left;
  padding: 0;
  padding-bottom: 50px;
}

.navbar .menu-title {
  color: #41434d;
  clear: both;
  display: block;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 50px;
  padding: 0;
  text-transform: uppercase;
  width: 100%;
}

.navbar .navbar-nav {
  float: none;
  position: relative;
}

.navbar .navbar-nav > li {
  padding-left: 30px;
  padding-right: 30px;
}

.navbar .navbar-nav > li.active {
  background: #fafafa;
}

.navbar .navbar-nav li {
  width: 100%;
}

.navbar .navbar-nav li.active .menu-icon,
.navbar .navbar-nav li:hover .toggle_nav_button:before,
.navbar .navbar-nav li .toggle_nav_button.nav-open:before {
  color: #03a9f3;
}

.navbar .navbar-nav li .dropdown-toggle:after {
  display: none;
}

.navbar .navbar-nav li > a {
  background: none !important;
  color: #384253;
  display: inline-block;
  font-size: 14px;
  line-height: 26px;
  padding: 10px 0;
  position: relative;
  width: 100%;
}

.navbar .navbar-nav li > a:hover,
.navbar .navbar-nav li > a:hover .menu-icon {
  color: #03a9f3;
}

.navbar .navbar-nav li > a .menu-icon {
  color: #607d8b;
  float: left;
  margin-top: 8px;
  width: 55px;
  text-align: left;
  z-index: 9;
}

.navbar .navbar-nav li > a .menu-title-text {
  font-size: 14px;
}

.navbar .navbar-nav li > a .badge {
  border-radius: 0;
  font-weight: 600;
  float: right;
  margin: 6px 0 0 0;
  padding: 0.4em 0.5em;
}

.navbar .navbar-nav li.menu-item-has-children {
  position: relative;
}

.navbar .navbar-nav li.menu-item-has-children a {
  line-height: 30px;
}

.navbar .navbar-nav li.menu-item-has-children a:before {
  content: "";
  position: absolute;
  top: 23px;
  right: 0;
  width: 8px;
  height: 8px;
  border-style: solid;
  border-width: 1px;
  border-color: #607d8b #607d8b transparent transparent;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.navbar .navbar-nav li.menu-item-has-children a:hover:before {
  border-color: #03a9f3 #03a9f3 transparent transparent;
}

.navbar .navbar-nav li.menu-item-has-children .sub-menu {
  background: #fff;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  overflow-y: hidden;
  padding: 0 0 0 35px;
}

.navbar .navbar-nav li.menu-item-has-children .sub-menu li {
  position: relative;
}

.navbar .navbar-nav li.menu-item-has-children .sub-menu i {
  color: #c8c9ce;
  float: left;
  padding: 0;
  position: absolute;
  left: 0;
  font-size: 14px;
  top: 9px;
}

.navbar .navbar-nav li.menu-item-has-children .sub-menu a {
  padding: 2px 0 2px 30px;
}

.navbar .navbar-nav li.menu-item-has-children .sub-menu a:before {
  content: "";
  display: none;
}

.navbar .navbar-nav li.menu-item-has-children .sub-menu a .menu-icon {
  top: 13px;
  text-align: left;
  width: 25px;
}

.navbar .navbar-nav li.menu-item-has-children.show a:before {
  border-color: transparent #607d8b #607d8b transparent;
  top: 20px;
  right: -5px;
}

.navbar .navbar-nav li.menu-item-has-children.show a:hover:before {
  border-color: transparent #03a9f3 #03a9f3 transparent;
}

.navbar .navbar-nav li.menu-item-has-children.show .sub-menu {
  max-height: 1000px;
  opacity: 1;
  position: static !important;
}

.navbar .navbar-nav > .active > a,
.navbar .navbar-nav > .active > a:focus,
.navbar .navbar-nav > .active > a:hover {
  color: #03a9f3;
}

.navbar-nav li span.count {
  background: #a9d86e;
  border-radius: 50%;
  color: #fff;
  font-family: 'Poppins', sans-serif;
  font-size: 9px;
  font-weight: 700;
  float: right;
  height: 20px;
  width: 20px;
  line-height: 20px;
  margin-right: 15px;
  text-align: center;
}

.open .navbar .navbar-brand.hidden {
  display: block;
}

.open .sub-menu .subtitle {
  display: block;
  font-size: 15px;
  line-height: 35px;
  color: #607d8b;
  border-bottom: 1px solid rgba(96, 125, 139, 0.15);
}

.open .sub-menu i {
  left: 15px !important;
}

.sub-menu .subtitle {
  display: none;
}

.sub-menu .subtitle > .menu-icon {
  display: none;
}

.open aside.left-panel {
  max-width: 83px;
  width: 83px;
}

.open aside.left-panel .navbar .navbar-header {
  padding: 0;
}

.open aside.left-panel .navbar .navbar-brand {
  display: none;
}

.open aside.left-panel .navbar .navbar-brand.hidden {
  display: block !important;
  text-align: center;
  width: 100%;
}

.open aside.left-panel .navbar .navbar-brand.hidden img {
  max-width: 100%;
  margin: 0 auto;
}

.open aside.left-panel .navbar .navbar-brand.d-md-none {
  display: block !important;
  margin: 13px 0 0;
  min-height: 67px;
  padding: 0;
  text-align: center;
}

.open aside.left-panel .navbar .navbar-nav:before {
  display: none !important;
}

.open aside.left-panel .navbar .navbar-nav li {
  position: relative;
  padding: 0 30px;
}

.open aside.left-panel .navbar .navbar-nav li a {
  font-size: 0;
  z-index: 0;
  -webkit-transition: none;
  transition: none;
}

.open aside.left-panel .navbar .navbar-nav li a .menu-icon {
  font-size: 20px;
  z-index: -1;
  text-align: center;
  width: inherit;
}

.open aside.left-panel .navbar .navbar-nav li a .menu-title-text {
  font-size: 0;
}

.open aside.left-panel .navbar .navbar-nav li a .badge {
  display: none;
}

.open aside.left-panel .navbar .navbar-nav li > a {
  max-width: 60px;
  padding-left: 0;
}

.open aside.left-panel .navbar .navbar-nav li.menu-item-has-children {
  overflow: hidden;
}

.open aside.left-panel .navbar .navbar-nav li.menu-item-has-children ul {
  padding-left: 0;
}

.open aside.left-panel .navbar .navbar-nav li.menu-item-has-children .sub-menu {
  display: block;
  left: 83px;
  top: 0;
}

.open
  aside.left-panel
  .navbar
  .navbar-nav
  li.menu-item-has-children
  .sub-menu
  li
  a {
  display: block;
  font-size: 14px;
  max-width: inherit;
  padding: 2px 15px 2px 25px;
  width: 100%;
}

.open
  aside.left-panel
  .navbar
  .navbar-nav
  li.menu-item-has-children
  .sub-menu
  li
  a
  .menu-icon {
  text-align: center;
}

.open aside.left-panel .navbar .navbar-nav li.menu-item-has-children.show {
  overflow: visible;
}

.open
  aside.left-panel
  .navbar
  .navbar-nav
  li.menu-item-has-children.show
  .sub-menu {
  position: absolute !important;
  width: 180px;
  -webkit-box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
  box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
}

.open aside.left-panel .navbar .navbar-nav li span.count {
  display: none;
  margin-right: 5px;
  z-index: 1;
}

.open aside.left-panel .navbar .navbar-nav li.active a:after {
  content: "";
  display: none;
}

.open aside.left-panel .navbar .navbar-nav .menu-title {
  font-size: 0;
  line-height: 0;
  opacity: 0;
  padding: 0;
}

.open aside.left-panel .menutoggle {
  background-color: #dc3545;
  color: #fff !important;
  right: -20px;
  text-align: center;
}

/* Right panel */

.right-panel {
  //margin-left: 280px;
  margin-top: 60px;
}

.right-panel .top-left {
  width: 550px;
  float: left;
}

.right-panel .top-rigth {
  float: left;
}

.right-panel .breadcrumbs {
  float: left;
  margin-top: 30px;
  padding: 0 1.875em;
  width: 100%;
}

.right-panel .breadcrumbs .breadcrumbs-inner {
  background-color: #fff;
}

.right-panel .breadcrumbs .col-lg-8 .page-header {
  float: left;
}

.right-panel .page-header {
  min-height: 50px;
  margin: 0px;
  padding: 0px 15px;
  background: #ffffff;
  border-bottom: 0px;
}

.right-panel .page-header h1 {
  font-size: 18px;
  padding: 15px 0;
}

.right-panel .page-header .breadcrumb {
  margin: 0px;
  padding: 13.5px 0;
  background: #fff;
  text-transform: capitalize;
}

.right-panel .page-header .breadcrumb > li + li:before {
  padding: 0 5px;
  color: #ccc;
  content: "/\00a0";
}

.right-panel header.header {
  background: #fff;
  border-bottom: 1px solid #e8e9ed;
  -webkit-box-shadow: none;
  box-shadow: none;
  clear: both;
  padding: 0 30px;
  height: 55px;
  position: fixed;
  left: 280px;
  left: 0;
  right: 0;
  top: 0;
  z-index: 999;
}

.right-panel .navbar-brand {
  width: 250px;
  display: inline-block;
}

.right-panel .menutoggle {
  padding-top: 7px;
}

.right-panel .navbar-header {
  width: 100%;
  background-color: #fff;
  padding: 0 1.25em 0 0;
}

.right-panel .navbar-header > a {
  display: inline-block;
}

.right-panel .navbar-brand {
  line-height: 42px;
}

.right-panel .navbar-brand img {
  max-width: 145px;
}

.right-panel .navbar-brand.hidden {
  display: none;
}

.open .right-panel {
  margin-left: 83px;
}

.open .let-panel {
  z-index: 999;
}

header.fixed-top {
  background: #fff;
  padding: 20px;
}

.menutoggle {
  background-color: transparent;
  color: #99abb4 !important;
  cursor: pointer;
  font-size: 1em;
  height: 40px;
  line-height: 40px;
  width: 40px;
  display: block;
  text-align: right;
}

.search-trigger {
  background: transparent;
  border: none;
  color: #99abb4;
  cursor: pointer;
  font-size: 16px;
  line-height: 55px;
  padding: 0;
  width: 43px;
}

header .form-inline {
  background: #131c21;
  display: none;
  height: 55px;
  margin: 0;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9999;
}

header .form-inline .search-form {
  height: 100%;
  max-width: 1025px;
  margin: 0 auto;
  position: relative;
}

header .form-inline .search-form input[type="text"] {
  background: #131c21;
  border: none;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #d3d3d3;
  font-size: 16px;
  height: inherit;
  margin-right: 0 !important;
  padding: 10px 36px 10px 15px;
  width: 100%;
}

header .form-inline .search-form input[type="text"].active,
header .form-inline .search-form input[type="text"]:focus {
  border-color: rgba(0, 0, 0, 0.125);
  outline: 0;
}

header .form-inline .search-form button {
  background: transparent;
  border: none;
  color: #fff;
  font-size: 16px;
  position: absolute;
  right: 15px;
  top: 50%;
  margin-top: -14px !important;
}

header .form-inline .search-form button:active,
header .form-inline .search-form button:focus,
header .form-inline .search-form button:visited,
header .form-inline .search-form .btn-outline-success:hover {
  background: transparent;
  border: none !important;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 0 !important;
}

header .form-inline .search-form.close {
  display: none;
}

.header-left {
  display: inline-block;
}

.header-left.open .form-inline {
  display: block;
}

.header-left .dropdown {
  display: inline-block;
}

.header-left .dropdown .dropdown-toggle {
  background: transparent;
  border: none;
  color: #99abb4;
  font-size: 16px;
  line-height: 55px;
  padding-top: 0;
  padding-bottom: 0;
}

.header-left .dropdown .dropdown-toggle:after {
  display: none;
}

.header-left .dropdown .dropdown-toggle .count {
  border-radius: 50%;
  color: #fff;
  font-size: 11px;
  height: 15px;
  width: 15px;
  line-height: 15px;
  right: 0;
  top: 12px;
  position: absolute;
}

.header-left .dropdown .dropdown-toggle:active,
.header-left .dropdown .dropdown-toggle:focus,
.header-left .dropdown .dropdown-toggle:visited {
  background: none !important;
  border-color: transparent !important;
  color: #292b35 !important;
}

.header-left .dropdown .dropdown-menu {
  background: #fff;
  border: none;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  top: 52px !important;
  left: inherit !important;
  right: 0 !important;
  -webkit-box-shadow: 0 3px 12px rgba(0, 0, 0, 0.05);
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.05);
  line-height: 35px;
}

.header-left .dropdown .dropdown-menu p {
  font-size: 15px;
  margin: 0;
  padding: 5px 15px;
}

.header-left .dropdown .dropdown-menu .dropdown-item {
  color: #99abb4;
  font-size: 13px;
  padding: 10px 15px 3px;
  text-overflow: ellipsis;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
}

.header-left .dropdown .dropdown-menu .dropdown-item:hover {
  background: rgba(233, 236, 239, 0.5);
}

.header-left .dropdown .dropdown-menu .dropdown-item .photo {
  float: left;
  margin-right: 15px;
  width: 25px;
}

.header-left .dropdown .dropdown-menu .dropdown-item .message .name {
  margin-top: -5px;
}

.header-left .dropdown .dropdown-menu .dropdown-item .message .time {
  font-size: 11px;
}

.header-left .dropdown .dropdown-menu .dropdown-item .message p {
  clear: both;
  font-size: 14px;
  margin: 0;
  padding: 0;
  text-overflow: ellipsis;
}

.header-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.header-menu .dropdown {
  line-height: 55px;
  margin-top: 0 !important;
}

.header-menu .dropdown .dropdown-toggle {
  line-height: 55px;
}

.user-area .dropdown-toggle {
  position: relative;
  z-index: 0;
}

.user-area .dropdown-toggle:before {
  content: "";
  position: absolute;
  right: -3px;
  bottom: 10px;
  background: #868e96;
  width: 11px;
  height: 11px;
  border-radius: 100%;
  border: 2px solid white;
  z-index: 1;
}

.user-area .dropdown-toggle.active:before {
  background: #49a342;
}

.dropdown-menu {
  border-radius: 0;
  -webkit-transform: none !important;
  transform: none !important;
}

.for-notification .dropdown-menu .dropdown-item {
  padding: 5px 15px !important;
  text-overflow: ellipsis;
}

.for-notification .dropdown-menu .dropdown-item i {
  float: left;
  font-size: 14px;
  margin: 5px 5px 0 0;
  text-align: left;
  width: 20px;
}

.for-notification .dropdown-menu .dropdown-item p {
  padding: 0 !important;
  text-overflow: ellipsis;
}

.user-area {
  float: right;
  padding-right: 0;
  position: relative;
  padding-left: 20px;
}

.user-area .user-menu {
  background: #fff;
  border: none;
  left: inherit !important;
  right: 0;
  top: 54px !important;
  margin: 0;
  max-width: 150px;
  padding: 5px 10px;
  position: absolute;
  width: 100%;
  z-index: 999;
  min-width: 150px;
  -webkit-box-shadow: 0 3px 12px rgba(0, 0, 0, 0.05);
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.05);
}

.user-area .user-menu .nav-link {
  color: #607d8b;
  display: block;
  font-size: 13px;
  line-height: 22px;
  padding: 5px 0;
}

.user-area .user-menu .nav-link > i {
  margin-right: 10px;
}

.user-area .user-avatar {
  float: right;
  width: 40px;
}

.user-area .user-info .name {
  color: #8c8c8c;
  font-size: 14px;
  position: relative;
  text-transform: uppercase;
}

.user-area .count {
  background: #d9534f;
  border-radius: 50%;
  color: #fff;
  font-family: 'Poppins', sans-serif;
  font-size: 9px;
  font-weight: 700;
  float: right;
  height: 20px;
  width: 20px;
  line-height: 20px;
  text-align: center;
}

.user-area .dropdown-toggle {
  line-height: 55px;
  height: 55px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.user-area .dropdown-toggle:after {
  display: none;
}

#menuToggle2 {
  padding-left: 25px;
}

#language-select {
  color: #f1f2f7;
  float: right;
  margin: 7px 20px 0 0;
  max-width: 80px;
}

#language-select:focus,
#language-select:visited {
  border: none;
  outline: 0;
}

#language-select .flag-icon {
  line-height: 55px;
}

#language-select .dropdown-toggle::after {
  display: none;
}

#language-select .dropdown-menu {
  background: #fff;
  border: none;
  border-radius: 0;
  left: -8px !important;
  min-width: inherit;
  padding: 0 5px;
  top: 81px !important;
}

#language-select .dropdown-menu .dropdown-item {
  line-height: 1.5;
  margin-right: 0;
  max-width: 25px;
  padding: 0;
}

#language-select .dropdown-menu .dropdown-item:hover {
  background: #fff;
}

#language-select .dropdown-menu .dropdown-item .flag-icon {
  line-height: 1.5;
  margin-right: 0;
  width: 25px;
}

.notification-show + .dropdown-menu,
.message-show + .dropdown-menu,
.language-show + .dropdown-menu {
  display: block;
}

.content {
  float: left;
  padding: 2.2em 1.875em 1.875em 1.875em;
  width: 100%;
}

.card .box-title {
  font-size: 1rem;
  padding: 5px 0 12px 0;
  line-height: 14px;
}

.card .head-title {
  font-weight: 600;
  font-size: 1.5em;
  padding: 5px 0 10px;
  margin-bottom: 0px;
  border-bottom: 1px solid #ddd;
}

.card .card-top,
.card .card-footer {
  background: #fafafa;
  border-bottom: 1px solid #e8e9ef;
  float: left;
  padding: 0.75em 1.125em;
  width: 100%;
}

.card .card-top .card-title,
.card .card-footer .card-title {
  color: #343a40;
  font-size: 0.875em;
  font-weight: 600;
  padding-top: 5px;
  text-transform: capitalize;
}

.card .card-top button,
.card .card-footer button {
  background: none;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #868e96;
  font-size: 12px;
  padding: 0 5px;
}

.card .card-top[class*="bg"] .card-title,
.card .card-footer[class*="bg"] .card-title {
  color: #fff;
}

.card .card-top[class*="bg"] button,
.card .card-footer[class*="bg"] button {
  color: #fff;
}

.card .card-top.p-0 .card-title,
.card .card-footer.p-0 .card-title {
  padding: 1.125em;
}

.card .card-top.bg-flat-color-1,
.card .card-footer.bg-flat-color-1 {
  background: #5c6bc0;
  border: none;
}

.card .card-top.bg-flat-color-2,
.card .card-footer.bg-flat-color-2 {
  background: #42a5f5;
  border: none;
}

.card .card-top.bg-flat-color-3,
.card .card-footer.bg-flat-color-3 {
  background: #ffa726;
  border: none;
}

.card .card-top.bg-flat-color-4,
.card .card-footer.bg-flat-color-4 {
  background: #ef5350;
  border: none;
}

.card .card-top.bg-flat-color-5,
.card .card-footer.bg-flat-color-5 {
  background: #4dbd74;
  border: none;
}

.card .card-top.bg-flat-color-6,
.card .card-footer.bg-flat-color-6 {
  background: #54D072;
  border: none;
}

.card .card-left {
  width: 60%;
}

.card .card-right {
  width: 40%;
}

.card .card-body {
  float: left;
  padding: 1.25em;
  position: relative;
  width: 100%;
  overflow: auto;
}

.card h4 {
  font-size: 1.1rem;
}

.card .user-header .media img {
  border: 5px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  -webkit-border-radius: 50%;
}

.card .card-header .card-actions button {
  display: block;
  float: left;
  width: 50px;
  padding: 0.75rem 0;
  margin: 0 !important;
  color: #fff;
  outline: 0;
  text-align: center;
  background: transparent;
  border: 0;
  border-left: 1px solid rgba(120, 130, 140, 0.4);
}

.card .card-footer {
  padding: 0.65rem 1.25rem;
  background-color: #f0f3f5;
  border-top: 1px solid #c2cfd6;
}

.card .card-footer ul li {
  display: table-cell;
  padding: 0 1rem;
  text-align: center;
}

.card .nav-tabs {
  margin-bottom: -1px;
}

.card .nav-tabs a {
  border-radius: 0;
  color: #b5bcc2;
  font-size: 12px;
  font-weight: 700;
  padding: 0.25em 0.5em;
  text-transform: uppercase;
}

.card .nav-tabs a:hover {
  border-color: transparent;
}

.card .nav-tabs .active a {
  border-color: #e8e9ef;
  color: #868e96 !important;
}

.action-menu .dropdown-toggle i {
  font-size: 12px;
}

.action-menu .dropdown-toggle::after {
  display: none;
}

.flotBar1,
.flotLine1 {
  height: 75px;
}

.flotRealtime2 {
  height: 445px;
}

.traffic-chart {
  height: 34vh;
}

#flotBar2 {
  height: 168px;
}

.gauge.g1 svg {
  fill: #ffa726 !important;
}

#area_chart {
  max-height: 280px;
}

#flotLine5 {
  height: 50px;
}

#flotPie1 {
  height: 90px;
}

#flotPie1 .pieLabel {
  display: none;
}

#flotPie1 .legend tr {
  padding-bottom: 5px;
}

#flotPie1 .legendColorBox {
  float: left;
  margin: 2px 5px 0 0;
}

#flotPie1 .legendColorBox > div {
  border: none !important;
}

#flotPie1 .legendLabel {
  color: #868e96;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
}

/* Stats Table */
.table-stats table {
  font-family: 'Poppins', sans-serif !important;
}

.table-stats table th,
.table-stats table td {
  border: none;
  border-bottom: 1px solid #e8e9ef;
  font-size: 12px;  
  padding: 10px;
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
  letter-spacing: 0.001em;
  color:rgba(0, 44, 69, 0.8) !important;
}

.table-stats table th {
  font-family: 'Poppins', sans-serif !important;
  color:rgba(0, 44, 69, 0.6) !important;
  font-size: 16px;
  font-weight: 700 !important;
  vertical-align: middle;
  text-transform: capitalize;
}

.table-stats table th img,
.table-stats table td img {
  margin-right: 10px;
  max-width: 45px;
}

.table-stats table th .name {
  color: #343a40;
  font-size: 14px;
  text-transform: capitalize;
}

.table-stats table td {
  color: #343a40;
  font-size: 14px;
  line-height: 24px;
  vertical-align: middle;
}
.table-stats table td.name::first-letter {
  text-transform: capitalize;
}
/* Tabs */

.nav-tabs a.active {
  color: #555;
  cursor: default;
  background-color: #fff;
  border: 1px solid #ddd;
  border-bottom-color: transparent;
}

.nav-tabs .dropdown .dropdown-menu {
  top: 100% !important;
}

.custom-tab .nav-tabs > a.active,
.custom-tab .nav-tabs > .active > a:focus,
.custom-tab .nav-tabs > li.active > a:hover {
  border-color: transparent transparent;
  color: #ff2e44;
  position: relative;
}

.custom-tab .nav-tabs > a.active:after,
.custom-tab .nav-tabs > li.active > a:focus:after,
.custom-tab .nav-tabs > li.active > a:hover:after {
  background: #ff2e44;
  bottom: -1px;
  content: "";
  height: 2px;
  left: 0;
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 1;
}

.card .card-header .card-actions {
  float: right;
}

.card .card-header .card-actions [class*="btn"] {
  border-left: 1px solid rgba(120, 130, 140, 0.4);
  color: #878787;
  display: inline-block;
  font-size: 16px;
  float: left;
  padding: 0 7px;
  width: inherit;
  text-align: center;
}

.social-buttons .card-body p button {
  padding-top: 0;
  padding-left: 0;
  padding-bottom: 0;
}

.social-buttons .only-icon .card-body p button {
  padding: 0;
}

.social-buttons .social i {
  padding: 0 10px;
  width: inherit !important;
}

.social-buttons .only-text p button {
  padding: 0 0.5rem;
}

.buttons button {
  margin: 2px 0;
}

/* Ribons */

.corner-ribon {
  text-align: center;
  width: 71px;
  height: 71px;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 20px;
}

.corner-ribon i {
  padding: 10px 0 0 35px;
  color: #fff;
}

.twt-feed .wtt-mark {
  color: rgba(255, 255, 255, 0.15);
  font-size: 160px;
  position: absolute;
  top: 10px;
  left: 40%;
}
/* Button Reset */

.btn,
.button {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  border-radius: 3;
  cursor: pointer;
}

/* Icons */

.icon-section {
  margin: 0 0 3em;
  clear: both;
  overflow: hidden;
}

.icon-container {
  width: 240px;
  padding: 0.7em 0;
  float: left;
  position: relative;
  text-align: left;
}

.icon-container [class^="ti-"],
.icon-container [class*=" ti-"] {
  color: #000;
  position: absolute;
  margin-top: 3px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.icon-container:hover [class^="ti-"],
.icon-container:hover [class*=" ti-"] {
  font-size: 2.2em;
  margin-top: -5px;
}

.icon-container:hover .icon-name {
  color: #000;
}

.icon-name {
  color: #aaa;
  margin-left: 35px;
  font-size: 14px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.icon-container:hover .icon-name {
  margin-left: 45px;
}

.fontawesome-icon-list .page-header {
  border-bottom: 1px solid #c9cdd7;
  padding-bottom: 9px;
  margin: 30px 0px 27px 0px;
}

.fontawesome-icon-list h2 {
  margin-top: 0;
  font-size: 20px;
  font-weight: 300;
}

.fontawesome-icon-list i {
  font-style: 16px;
  padding-right: 10px;
}

.social-box i {
  line-height: 110px;
}

.social-box ul {
  display: inline-block;
  margin: 7px 0 0;
  padding: 10px;
  width: 100%;
}

.social-box ul li {
  color: #949ca0;
  font-size: 14px;
  font-weight: 700;
  padding: 0 10px 0 0;
  text-align: right;
}

.social-box ul li:last-child {
  padding-left: 10px;
  padding-right: 0;
  text-align: left;
}

.social-box ul li span {
  font-size: 14px;
}

.login-logo {
  text-align: center;
  margin-bottom: 15px;
}

.login-logo span {
  color: #ffffff;
  font-size: 24px;
}

.login-content {
  max-width: 540px;
  margin: 8vh auto;
}

.login-form {
  background: #ffffff;
  padding: 30px 30px 20px;
  border-radius: 2px;
}

.login-form h4 {
  color: #878787;
  text-align: center;
  margin-bottom: 50px;
}

.login-form .checkbox {
  color: #878787;
}

.login-form .checkbox label {
  text-transform: none;
}

.login-form .btn {
  width: 100%;
  text-transform: uppercase;
  font-size: 14px;
  padding: 15px;
  border: 0px;
}

.login-form label {
  color: #878787;
  text-transform: uppercase;
}

.login-form label a {
  color: #ff2e44;
}

.social-login-content {
  margin: 0px -30px;
  border-top: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
  padding: 30px 0px;
  background: #fcfcfc;
}

.social-button {
  padding: 0 30px;
}

.social-button .facebook {
  background: #3b5998;
  color: #fff;
}

.social-button .facebook:hover {
  background: #344e86;
}

.social-button .twitter {
  background: #00aced;
  color: #fff;
}

.social-button .twitter:hover {
  background: #0099d4;
}

.social-button i {
  padding: 19px;
}

.register-link a {
  color: #ff2e44;
}

.cpu-load {
  width: 100%;
  height: 272px;
  font-size: 14px;
  line-height: 1.2em;
}

.cpu-load-data-content {
  font-size: 18px;
  font-weight: 400;
  line-height: 40px;
}

.cpu-load-data {
  margin-bottom: 30px;
}

.cpu-load-data li {
  display: inline-block;
  width: 32.5%;
  text-align: center;
  border-right: 1px solid #e7e7e7;
}

.cpu-load-data li:last-child {
  border-right: 0px;
}

.nestable-cart {
  overflow: hidden;
}

/* Forms */

.input-group-addon {
  background-color: transparent;
  border-left: 0;
}

.input-group-addon,
.input-group-btn {
  white-space: nowrap;
  vertical-align: middle;
}

.input-group-addon {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  color: #495057;
  text-align: center;
  background-color: #e9ecef;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.flotTip {
  background: #252525;
  border: 1px solid #252525;
  padding: 5px 15px;
  color: #ffffff;
}

.flot-container {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  height: 275px;
  padding: 20px 15px 15px;
  margin: 15px auto 30px;
  background: transparent;
}

.flot-pie-container {
  height: 275px;
}

.flotBar-container {
  height: 275px;
}

.flot-line {
  width: 100%;
  height: 100%;
  font-size: 14px;
  line-height: 1.2em;
}

.legend table {
  border-spacing: 5px;
}

#chart1,
#flotBar,
#flotCurve {
  width: 100%;
  height: 275px;
}

.morris-hover {
  position: absolute;
  z-index: 1;
}

.morris-hover.morris-default-style .morris-hover-row-label {
  font-weight: bold;
  margin: 0.25em 0;
}

.morris-hover.morris-default-style .morris-hover-point {
  white-space: nowrap;
  margin: 0.1em 0;
}

.morris-hover.morris-default-style {
  border-radius: 2px;
  padding: 10px 12px;
  color: #666;
  background: rgba(0, 0, 0, 0.7);
  border: none;
  color: #fff !important;
}

.morris-hover-point {
  color: rgba(255, 255, 255, 0.8) !important;
}

#morris-bar-chart {
  height: 285px;
}

.map,
.vmap {
  width: 100%;
  height: 400px;
}

.btn-toolbar {
  float: left !important;
}

.btn-toolbar .btn-outline-secondary:not([disabled]):not(.disabled):active,
.btn-toolbar .btn-outline-secondary:not([disabled]):not(.disabled).active,
.btn-toolbar .show > .btn-outline-secondary.dropdown-toggle {
  background-color: #212529;
  border-color: #212529;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
}

.btn-toolbar .btn-outline-secondary:hover {
  background-color: #212529;
  border-color: #212529;
  color: #fff;
}

/*    Widget One
---------------------------*/

.dib {
  display: inline-block;
}

.stat-widget-one .stat-icon {
  vertical-align: top;
}

.stat-widget-one .stat-icon i {
  font-size: 30px;
  border-width: 3px;
  border-style: solid;
  border-radius: 100px;
  padding: 15px;
  font-weight: 900;
  display: inline-block;
}

.stat-widget-one .stat-content {
  margin-left: 30px;
  margin-top: 7px;
}

.stat-widget-one .stat-text {
  font-size: 14px;
  color: #868e96;
}

.stat-widget-one .stat-digit {
  font-size: 24px;
  color: #373757;
}

/*    Widget Two
---------------------------*/

.stat-widget-two {
  text-align: center;
}

.stat-widget-two .stat-digit {
  font-size: 1.75rem;
  font-weight: 500;
  color: #373757;
}

.stat-widget-two .stat-digit i {
  font-size: 18px;
  margin-right: 5px;
}

.stat-widget-two .stat-text {
  font-size: 16px;
  margin-bottom: 5px;
  color: #868e96;
}

.stat-widget-two .progress {
  height: 8px;
  margin-bottom: 0;
  margin-top: 20px;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.stat-widget-two .progress-bar {
  -webkit-box-shadow: none;
  box-shadow: none;
}

/*    Widget Three
---------------------------*/

.stat-widget-three .stat-icon {
  display: inline-block;
  padding: 33px;
  position: absolute;
  line-height: 21px;
}

.stat-widget-three .stat-icon i {
  font-size: 30px;
  color: #ffffff;
}

.stat-widget-three .stat-content {
  text-align: center;
  padding: 15px;
  margin-left: 90px;
}

.stat-widget-three .stat-digit {
  font-size: 30px;
}

.stat-widget-three .stat-text {
  padding-top: 4px;
}

.home-widget-three .stat-icon {
  line-height: 19px;
  padding: 27px;
}

.home-widget-three .stat-digit {
  font-size: 24px;
  font-weight: 300;
  color: #373757;
}

.home-widget-three .stat-content {
  text-align: center;
  margin-left: 60px;
  padding: 13px;
}

/*    Widget Four
---------------------------*/

.stat-widget-four {
  position: relative;
}

.stat-widget-four .stat-icon {
  display: inline-block;
  position: absolute;
  top: 5px;
}

.stat-widget-four i {
  display: block;
  font-size: 36px;
}

.stat-widget-four .stat-content {
  margin-left: 40px;
  text-align: center;
}

.stat-widget-four .stat-heading {
  font-size: 20px;
}

/*    Widget Five
---------------------------*/

.stat-widget-five {
  min-height: 60px;
}

.stat-widget-five .stat-icon {
  font-size: 50px;
  line-height: 50px;
  position: absolute;
  left: 30px;
  top: 20px;
}

.stat-widget-five .stat-content {
  margin-left: 100px;
}

.stat-widget-five .stat-text {
  color: #455a64;
  font-size: 20px;
}

.stat-widget-five .stat-heading {
  color: #99abb4;
  font-size: 14px;
}

.horizontal:before {
  background: #ffffff;
  bottom: 0;
  content: "";
  height: 38px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  width: 1px;
}

/* Mixed Styles */

.badges h1,
.badges h2,
.badges h3,
.badges h4,
.badges h5,
.badges h6 {
  margin: 5px 0;
}

.vue-lists ul,
.vue-lists ol {
  padding-left: 30px;
}

.card .dropdown.float-right .dropdown-menu {
  left: inherit !important;
  right: 0 !important;
  top: 93% !important;
}

.dataTables_paginate .pagination {
  border-radius: 0;
}

.dataTables_paginate .pagination li {
  border-radius: 0 !important;
}

.dataTables_paginate .pagination li a {
  border-radius: 0 !important;
  color: #292b35;
}

.dataTables_paginate .pagination li.active a {
  background: #292b35;
  border-color: #292b35;
  color: #fff;
}

.dataTables_paginate .pagination li:hover a {
  background: #292b35;
  border-color: #292b35;
  color: #fff;
}

/* Footer */

footer.site-footer {
  padding: 0 1.875em;
}

footer.site-footer .footer-inner {
  color: #78909c;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  line-height: 1.5;
  padding: 2.45em 1.125em;
}

footer.site-footer .footer-inner a {
  color: #6a7074;
}


@media (max-width: 1500px) {
.loginBg .loginBGbox .while-logo {
   
  margin-top: 90px;
}
}

@media (max-width: 1368px) {
  .twt-category {
    margin-bottom: 0;
  }
  .twt-feed {
    max-height: 155px;
  }
  .twt-feed img {
    height: 75px;
    width: 75px;
  }
  .stat-widget-one .stat-content {
    margin-left: 15px;
  }
  .card-body {
    padding: 15px;
  }
  .badges button {
    margin: 2px 0;
  }
  #g2 {
    padding-top: 33px;
  }

  .loginBg .loginBGbox .while-logo {
    margin-top: 40px;
}

}

@media (max-width: 1024px) {
  .open aside.left-panel {
    width: 280px;
    max-width: 280px;
  }

  .loginBg .loginBGbox .while-logo {
    margin-top: 40px;
}
  aside.left-panel:hover {
    overflow-x: inherit;
  }
  aside.left-panel {
    overflow-x: scroll;
  }
  aside.left-panel {
    max-width: 83px;
    width: 83px;
  }
  aside.left-panel .navbar .navbar-header {
    padding: 0;
  }
  aside.left-panel .navbar .navbar-brand {
    display: none;
  }
  aside.left-panel .navbar .navbar-brand.hidden {
    padding: 0 0 10px;
    text-align: center;
    width: 100%;
  }
  aside.left-panel .navbar .navbar-brand.hidden img {
    max-width: 100%;
    margin: 0 auto;
  }
  aside.left-panel .navbar .navbar-brand.d-md-none {
    margin: 13px 0 0;
    min-height: 67px;
    padding: 0;
    text-align: center;
  }
  aside.left-panel .navbar .navbar-nav:before {
    display: none !important;
  }
  aside.left-panel .navbar .navbar-nav li {
    position: relative;
  }
  aside.left-panel .navbar .navbar-nav li a {
    font-size: 0;
    z-index: 0;
    -webkit-transition: none;
    transition: none;
  }
  aside.left-panel .navbar .navbar-nav li a .menu-icon {
    font-size: 20px;
    z-index: -1;
    text-align: center;
    width: inherit;
  }
  aside.left-panel .navbar .navbar-nav li a .menu-title-text {
    font-size: 0;
  }
  aside.left-panel .navbar .navbar-nav li a .badge {
    display: none;
  }
  aside.left-panel .navbar .navbar-nav li > a {
    max-width: 60px;
    padding-left: 0;
  }
  aside.left-panel .navbar .navbar-nav li.menu-item-has-children {
    overflow: hidden;
  }
  aside.left-panel .navbar .navbar-nav li.menu-item-has-children a:before {
    content: "";
    display: none;
  }
  aside.left-panel .navbar .navbar-nav li.menu-item-has-children ul {
    padding-left: 0;
  }
  aside.left-panel .navbar .navbar-nav li.menu-item-has-children .sub-menu {
    display: block;
    left: inherit;
    right: -160px;
    top: 0;
  }
  aside.left-panel
    .navbar
    .navbar-nav
    li.menu-item-has-children
    .sub-menu
    li
    a {
    display: block;
    font-size: 14px;
    max-width: inherit;
    padding: 2px 15px 2px 35px;
    width: 100%;
  }
  aside.left-panel
    .navbar
    .navbar-nav
    li.menu-item-has-children
    .sub-menu
    li
    a
    .menu-icon {
    text-align: center;
  }
  aside.left-panel .navbar .navbar-nav li.menu-item-has-children .sub-menu i {
    left: 10px;
  }
  aside.left-panel .navbar .navbar-nav li.menu-item-has-children.show {
    overflow: visible;
  }
  aside.left-panel
    .navbar
    .navbar-nav
    li.menu-item-has-children.show
    .sub-menu {
    position: absolute !important;
  }
  aside.left-panel .navbar .navbar-nav li span.count {
    display: none;
    margin-right: 5px;
    z-index: 1;
  }
  aside.left-panel .navbar .navbar-nav li.active a:after {
    content: "";
    display: none;
  }
  aside.left-panel .navbar .navbar-nav .menu-title {
    font-size: 0;
    line-height: 0;
    opacity: 0;
    padding: 0;
  }
  aside.left-panel .menutoggle {
    display: none;
  }
  .open aside.left-panel {
    max-width: 280px;
    width: 280px;
  }
  .open aside.left-panel .navbar .navbar-nav li > a {
    width: 100%;
    max-width: 100%;
    font-size: 14px;
  }
  .open aside.left-panel .navbar .navbar-nav li > a .menu-icon {
    width: 55px;
    font-size: inherit;
    text-align: left;
  }
  .open
    aside.left-panel
    .navbar
    .navbar-nav
    li.menu-item-has-children.show
    .sub-menu {
    position: static !important;
    margin-left: 45px;
  }
  .open aside.left-panel .navbar .navbar-nav .menu-title {
    padding-left: 30px;
    padding-right: 30px;
    font-size: 14px;
    line-height: 50px;
    opacity: 1;
  }
  .navbar .main-menu {
    padding: 0;
  }
  #language-select,
  .user-area .dropdown-toggle,
  header .form-inline {
    height: 48px;
  }
  .header-left .dropdown .dropdown-menu {
    top: 47px !important;
  }
  #language-select .dropdown-menu {
    top: 49px !important;
  }
  .user-area .user-menu {
    top: 51px !important;
  }
  .content {
    padding: 1.25em;
  }
  .flotRealtime2 {
    height: 330px;
  }
  .card {
    margin-bottom: 1.25em;
  }
  .card .card-header {
    position: relative;
  }
  .card .card-header strong {
    display: block;
  }
  .card .card-header small {
    float: left;
  }
  .card .card-header .card-actions {
    right: 0;
    top: 0.75rem;
    position: absolute;
  }
  .card-footer.bg-white.br-0.pl-5.pr-5.pt-0.pb-5 {
    padding-bottom: 1.5em !important;
    padding-left: 1em !important;
    padding-right: 1em !important;
  }
  #g2 {
    height: auto !important;
    padding-top: 7px;
  }
  #flotBar2 {
    height: 150px;
  }
  #g1 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-top: 0 !important;
  }
  .card-footer.bg-white [class*="float"] {
    display: block;
    float: none !important;
    text-align: center;
  }
  .card-footer.bg-white [class*="float"].currency {
    display: inline-block;
  }
  .card-footer.bg-white [class*="float"].fa {
    display: inline-block;
    float: left !important;
  }
  #traffic-chart {
    height: 232px !important;
  }
  i.p-4.font-3xl.width-90.text-center.mr-3.float-left.text-light {
    font-size: 1.8125em;
    width: 75px;
  }
  .card i + h4 {
    display: inline;
    padding-top: 1em !important;
  }
  .card-footer.bg-white.br-0.pl-5.pr-5.pt-1.pb-4.mb-3 {
    padding-left: 1em !important;
    padding-right: 1em !important;
  }
  .font-4xl {
    font-size: 2.75em;
  }
  .media.p-4.height-100,
  .widget .row.p-4.height-93 {
    padding: 1rem !important;
  }
  .height-100 {
    height: 63px;
  }
  .widget .social .rounded-circle {
    max-width: 60px;
    margin-top: 8px;
  }
  footer.site-footer {
    padding: 0 1.125em;
  }
  footer.site-footer .footer-inner {
    padding: 1.75em 1em;
  }
  //.right-panel{margin-left:83px;}
}
@media (max-width: 992px) {
  [class*="col"].no-padding {
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
  }
  .card.small .onlyForDesktop {
    display: block;
  }
  .card.small .content-body {
    display: none;
  }
}
// custom css
.card .nav-tabs a {
  padding: 0px 16px 7px 16px;
}
.custom-tab .nav-tabs > a.active {
  color:#54D072;
}
.custom-tab .nav-tabs > a.active:after {
  background:#54D072;
}
.btn-wrapper {
  margin: 16px 0;
}
.clear {
  clear: both;
}
.mgl-16 {
  margin-left: 16px;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.table thead th {
  border-bottom: 1px solid #dee2e6 !important;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #002c45; 
  vertical-align: top;
  white-space: nowrap;
}
.primary {
  color: #4bbce8;
}
.btn-primary {
  color: #fff;
  background-color: #4bbce8 !important;
  border-color: #4bbce8 !important;
}
@media (min-width: 1200px) {
  .container {
    max-width: 90% !important;
  }
}
.logout {
  background: transparent;
  justify-content: center;
  line-height: 36px;
  font-size: 14px;
  color: #384253;
  border: 0;
}
.logout:hover {
  color: #4bbce8;
}
.astrix {
  color: #ff0011;
}
.alert-icon {
  line-height: 75px;
}
body .alert-wrapper {
  z-index: 1099;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: block;
}
body .alert-wrapper .alert-danger {
  background-image: linear-gradient(-60deg, #cb356b -20%, #bd3f32 200%);
  color: #fff;
}

body .alert-wrapper .alert {
  border: 0;
  font-size: 14px;
  font-weight: 500;
  box-shadow: 0 14px 46px -8px rgba(0, 0, 0, 0.4);
  min-height: 120px;
  padding: 10px 30px 20px;
  border-radius: 12px;
  text-align: center;
  position: relative;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-wrapper span {
  display: block;
}
.alert-wrapper .alert .close {
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  background: #fff;
  padding: 5px 10px;
  border-radius: 12px;
  font-size: 12px;
  text-shadow: none;
  opacity: 1;
  box-shadow: 0 0 20px -2px rgba(0, 0, 0, 0.22);
  cursor: pointer;
}
.alert-wrapper .alert-danger .close {
  color: #bd3f32;
}
.alert-wrapper .alert-success {
  background-image: linear-gradient(-60deg, #0ba360 -20%, #3cba92 200%);
  color: #fff;
}
.alert-wrapper .alert-success .close {
  color: #22ad75;
}
.card.small {
  min-height: 150px;
}
svg:not(:root).svg-inline--fa {
  overflow: visible;
}
.svg-inline--fa.fa-w-18 {
  width: 1.125em;
}
.svg-inline--fa {
  display: inline-block;
  font-size: inherit;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}
.onlyForDesktop {
  background: #4bbce8;
  width: 100%;
  height: 100%;
  max-height: 320px;
}
.onlyForDesktop .info-icon {
  color: #155b97;
  font-size: 60px;
}
.onlyForDesktop .info-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75%;
  text-align: center;
}
.onlyForDesktop .info-text {
  color: #fff;
}
.onlyForDesktop:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

body .tooltipBox {
  position: relative;
  display: inline-block;
  opacity: 1 !important;
}
body .tooltipBox img {
  width: 16px;
  height: 16px;
  margin-left: 8px;
  opacity: 0.4;
}
body .tooltiptextBox {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  border: 1px solid #babcc5;
  border-radius: 6px;
  background-color: white;
  padding: 8px;
  color: #666;
  font-size: 14px;
  line-height: 140%;
  font-weight: 300;
  text-align: left;
  left: 30px;
  min-width: 190px;
}
body .tooltipBox.lastTooltip{
  .tooltiptextBox{
    top:-173px;
    &:after{
      top:175px;
    }
  }
}
body .tooltipBox .tooltiptextBox::after {
  content: "";
  position: absolute;
  top: 7px;
  right: 100%;
  // margin-top: -11px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #666 transparent transparent;
}

body .tooltipBox:hover .tooltiptextBox {
  visibility: visible;
}
.c-btn {
  background: rgba(0, 44, 69, 0.04);
  border: 1px solid rgba(0, 44, 69, 0.08);
  box-sizing: border-box;
  border-radius: 0px 24px 24px 16px !important;
  -webkit-border-radius: 0px 24px 24px 16px !important;
  -moz-border-radius: 0px 24px 24px 16px !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.001em;
  color: #002C45;
  opacity: 0.8;
  max-width: 360px;
}
.selected-list .c-angle-down, .selected-list .c-angle-up{
  width: 12px;
  height: 8px;
  top: 38%;
  right: 4px;
}
.c-btn span,h5.list-message {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.001em;
  color: #002C45;
  opacity: 0.8;
}
.list-filter .c-search{top:5px;}
.selected-list .c-list .c-token {
  background: #54D072;
  color: #ffffff;
}
.c-btn span.c-label {
  color: #ffffff;
}
.pure-checkbox input[type="checkbox"] + label:before {
  color: #54D072;
  border: 1px solid #54D072;
}
.dropdown-list ul {
  max-height: 200px;
}
.connectionTitle {
  color: #4bbce8;
  font-weight: 600;
  font-size: 26px;
  margin-bottom: 0;
}
.GuPagination {
  width: 100%;
  display: table;
  margin: 0 auto;
  position: relative;
  .ngx-pagination {
    margin: 16px auto;
    text-align: center;
    list-style: none;
    li {
      display: inline-block;
      a {
        color: #909dae;
        font-size: 14px;
        text-decoration: none;
        cursor: pointer;
        &:hover {
          background: transparent;
        }
      }
      a.activeindex {
        font-weight: 500;
        color: #4bbce8;
      }
    }
    li.current {
      font-weight: 500;
      color: #4bbce8;
      background: transparent;
    }
    li.small-screen {
      display: none;
    }
  }
  .countNumber {
    position: absolute;
    top: 20px;
    right: 12px;
    font-size: 14px;
    color: #909dae;
  }
}
.wordcloudPagination {
  width: 100%;
  display: table;
  margin: 0 auto;
  position: relative;
  .ngx-pagination {
    margin: 24px auto 0px auto;
    text-align: center;
    list-style: none;
    li {
      display: inline-block;
      a {
        color: #909dae;
        font-size: 14px;
        text-decoration: none;
        cursor: pointer;
        &:hover {
          background: transparent;
        }
      }
      a.activeindex {
        font-weight: 500;
        color: #4bbce8;
      }
    }
    li.current {
      font-weight: 500;
      color: #4bbce8;
      background: transparent;
    }
    li.small-screen {
      display: none;
    }
  }

  button {
    position: absolute;
    right: 0;
    top: 20px;
  }
}

.newGuPagination{
  position: relative;
  .ngx-pagination {
    margin: 16px auto;
    text-align: left;
    list-style: none;
    padding-left: 5px;
    li{
      height: 20px;
      vertical-align: middle;
      a{
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #002C45;
        opacity: 0.6;
        &:hover{        
          color: #54D072;   
          background: transparent;      
        }
        &:focus{
          outline: none;
        }
      }
     
    }
    li.current{    
      padding:13px 10px;
      background: #54D072;
      border-radius: 24px 24px 24px 0px;
      position: relative;
      span{
        font-size: 14px;
        line-height: 2px;
        font-style: normal;
        font-weight: 500;
        color: #FFFFFF;
        display: table-cell;
        vertical-align: middle;
      }     
    }
    
  }
  .countNumber{
    position: absolute;
    top:0;
    right: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.001em;
    color: #002C45;
    opacity: 0.8;
  }
}

.content-tab-wrapper {
  text-align: center;
  ul {
    display: inline-block;
    padding: 8px 14px;
    -webkit-box-shadow: inset 0 0 10px 0 #dde3eb;
    box-shadow: inset 0 0 10px 0 #dde3eb;
    background: #eaeef2;
    border-radius: 30px;
    margin-bottom: 30px;
    li {
      display: inline-block;
      a {
        font-size: 14px;
        font-weight: 500;
        color: #909dae;
        text-decoration: none;
        position: relative;
        padding: 6px 24px;
        &:before {
          content: "";
          position: absolute;
          left: 10px;
          top: 11px;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: #909dae;
        }
      }
      a.active {
        background: #ffffff;
        font-size: 14px;
        border-radius: 30px;
        color: #555555;
        &:before {
          background: #4bbce8;
        }
      }
    }
  }
}
.mat-dialog-container {
  background: #ffffff;
  color: #424242;
  font-weight: 400;
  padding: 24px !important;
  border-radius: 16px !important;
}
.editMessageStatus .mat-toolbar {
  background: transparent;
  color: #424242;
  font-weight: 400;
}
.editMessageStatus .mat-grid-list .mat-grid-tile {
  position: static;
  max-height: 150px;
}
.editMessageStatus .mat-grid-list {
  max-height: 150px;
  overflow-y: hidden;
}
.editMessageStatus .mat-radio-outer-circle {
  border-color: #424242;
}
.editMessageStatus .mat-grid-tile .controles-container {
  padding: 0;
}
.editMessageStatus .mat-raised-button:not([class*="mat-elevation-z"]) {
  box-shadow: none;
  border-radius: 0.25rem !important;
}

.bredcrumbLink:hover {
  text-decoration: none;
  color: #4bbce8;
}
.bredcrumbLink {
  font-size: 14px;
}
.bredcrumbLink,
.bredcrumbLink .fa.fa-angle-left {
  color: #4bbce8;
}
.MsgHeading {
  margin: 16px 0 42px 0;
}
.advanceConfig {
  margin: 24px 20px 0px 30px;
}
.subHeading {
  font-size: 14px;
  font-weight: 500;
  color: #909dae;
}
.ascent {
  color: #4bbce8;
}
#reportsdaterangepick .modal-content {
  height: 450px;
  min-width: 560px;
}
#reportsdaterangepick .modal-title {
  font-size: 1rem;
}
.editMessageStatus .md-drppicker {
  top: 70px !important;
}
.ofV {
  overflow: visible !important;
}
.md-drppicker {
  left: 15px !important;
}
td.today.active.start-date.active.end-date.available,
.md-drppicker td.start-date.end-date {
  //border-radius: 0 !important;
  background-color: #54D072;
  border-radius: 50px 50px 50px 0px !important;
}
.md-drppicker th{
  color: #002C45 !important;
  opacity: 0.6;
}
.md-drppicker td.active, .md-drppicker td.active:hover {
  background-color: #54D072 !important;
  border-color: transparent;
  color: #fff !important;
}
.md-drppicker option.disabled,
.md-drppicker td.disabled {
  text-decoration: none !important;
}
.md-drppicker td.active{
  background-color: #54D072;
  border-radius: 50px 50px 50px 0px !important;
}
mark {
  background-color: #54D072 !important;
  padding: 2px 3px !important;
  color: #ffffff;
  border-radius: 2px;
}
angular-tag-cloud span {
  cursor: pointer !important;
}
// .md-drppicker{
//   top:70px !important;
// }
.dRangeBox .md-drppicker{
  top:110px !important;
}
.md-drppicker .calendar td, .md-drppicker .calendar th{
  min-width: 34px !important;
}
@media (min-width: 576px) {
  #reportsdaterangepick .modal-dialog {
    max-width: 530px;
    margin: 1.75rem auto;
  }
}

/* For new dashboard theme */
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 31px;
  margin-bottom: 12px;
  cursor: pointer;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  color:rgba(0, 44, 69, 0.8); 
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmarkBox {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border-radius: 2px;
  border: 2px solid #DADADA;
}

.checkbox-container:hover input ~ .checkmarkBox {
  background-color: #54D072;
  border: 2px solid #54D072;
}

.checkbox-container input:checked ~ .checkmarkBox {
  background-color:#54D072;
  border: 2px solid #54D072;
}

.checkmarkBox:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-container input:checked ~ .checkmarkBox:after {
  display: block;
}

.checkbox-container .checkmarkBox:after {
  left: 4px;
  top: -1px;
  width: 6px;
  height: 13px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.bg {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 0;
  height: 80vh;
  border-bottom-right-radius: 50vh;
  background-color: rgba(0, 44, 69, 0.04);
}
.title-wrapper{
  display: flex;
  .left-column{
    flex: 60%;
  }
  .right-column{
    flex: 40%;
    .btn-box{
      text-align: right;
    }
  }
}
.new-dashboard-wrapper {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.page-title {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  text-transform: capitalize;
  // display: flex;
  // align-items: flex-end;
  color: #002c45;
  opacity: 0.8;
  margin-bottom: 20px;
}
.filter-wrapper {
  margin-bottom: 28px;
  font-size: 14px;
  .filterText{
    display: inline-block;
    color: #002C45;
  }
}
.common-box-wrapper{
  margin-bottom: 1.5rem;
  height: 36px;
  position: relative;
  .searchBox{
    position: relative;
    display: inline-block;
    .searchText {
      background: rgba(0, 44, 69, 0.04);
      border: 1px solid rgba(0, 44, 69, 0.08);
      box-sizing: border-box;
      border-radius: 0px 24px 24px 16px;
      height: 36px;
      padding: 4px 8px 4px 24px;
      width: 200px;
      position: relative; 
    }
    .searchIcon {
      position: absolute;
      background: url("assets/images/search.svg");
      background-repeat: no-repeat;
      width: 20px;
      height: 20px;
      left: 6px;
      top: 6px;
    }
  }
  .filterBox{
    display: inline-block;
    min-width: 160px;
  }

}

/*For Sidebar navigation*/

.sidebar {
  width: 60px;
  position: fixed;
  z-index: 9999;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100vh;
  padding-top: 1rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-bottom-right-radius: 4em;
  background-color: #000;
  color: rgba(255, 255, 255, 0.9);
  &:hover{.collapse.show{display: block;}}
  .collapse.show{display: none;}
  .gu-logo {
    padding-bottom: 1rem;
    display: block;
    padding-left: 0.5rem;
    position: relative;
  }

  h6 {
    //display: none;
    visibility: hidden;
    text-align: left;
    padding-left: 1rem;
  }

  .logo-normal {
    position: relative;
    p {
      position: absolute;
      top: 0px;
      right: -130px;
      margin: 0;     
    }
  }

  .side-nav-menu {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;

    a {
      width: 100%;
      float: left;
      margin-bottom: 0.3rem;
      padding-top: 0.2rem !important;
      padding-bottom: 0.2rem !important;
      padding-left: 1rem;
      padding-right: 1rem;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      border-top-right-radius: 2rem;
      border-bottom-right-radius: 2rem;
      -webkit-transition: all 200ms ease-out;
      transition: all 200ms ease-out;
      color: #fff;
      font-family: 'Poppins', sans-serif;
      line-height: 30px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      .nav-ic,
      .nav-ic-hover {
        position: relative;
        width: 2rem;
        height: 2rem;
        float: left;
        margin-right: 0.5rem;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
      }
      .nav-ic-hover {
        display: none;
      }
      &:hover,
      &.active,
      a.active {
        background-color: #ffffff;
        .nav-ic {
          display: none;
        }
        .nav-ic-hover {
          display: block;
        }
        .text-block-28 {
          color: #002c45;
        }
      }
    }

    ul ul a {
      padding-left: 3.5rem;
      //margin-left: 0.5rem;
      font-weight: normal;
    }
    ul ul a:hover{
      color: #002c45;
    }
    .dropdown-toggle::after {
      border-top: none;
      border-right: none;
    }
  }

  .text-block-28 {
    text-indent: -999rem;
    width: calc(100% - 2.5rem);
    float: left;
    padding-top: 2px;
    i.fa.fa-chevron-down {
      font-size: 0.8rem;
      float: right;
      line-height: 29px;
      font-weight: normal;
      padding-right: 0rem;
    }
  }

  .organisation {
    .ngo-wrapper {
      background: #04c3cb;
      top: 33px !important;
      border: none;
      padding-bottom: 0;
      min-height: 260px;
      .searchBox {
        position: relative;
        margin: 0rem 1rem 1rem 1rem;
        .search {
          width: 100%;
          background: #ffffff;
          border: 1px solid rgba(0, 44, 69, 0.08);
          box-sizing: border-box;
          border-radius: 0px 24px 24px 16px;
          height: 34px;
          padding: 5px 16px 5px 32px;
        }
        .searchIcon {
          position: absolute;
          background: url("assets/images/search.svg");
          background-repeat: no-repeat;
          width: 20px;
          height: 20px;
          left: 6px;
          top: 6px;
        }
      }
    }
    .btn-group {
      width: 100%;
      .dropdown-toggle {
        padding-left: 1rem;
        .orgName {         
          text-align: left;
          padding-left: 0.9rem;
          font-size: 14px;
          line-height: 30px;
          i {
            font-size: 0.8rem;
            line-height: 27px;
            font-weight: normal;
            float: right;
            position: absolute;
            right: 8px;
          }
        }
      }
      .dropdown-toggle::after {
        border-top: none;
        border-right: none;
        border-left: none;
      }
    }

    .imageIcon {
      float: left;
      height: 30px;
      img {
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        border: 1px solid #fff;
      }
    }
    .orgName {
      width: calc(100% - 2rem);
      float: left;
      color: #ffffff;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 210px;
      overflow: hidden;
    }
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0 1rem;
      background: #04c3cb;
      border: none;
      max-width: 250px;
      max-height: 190px;
      overflow-y: auto;
      li {
        display: block;
        float: left;
        width: 100%;
        margin-bottom: 10px;
        cursor: pointer;
        .orgName {
          padding-left: 0.9rem;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          line-height: 28px;
          font-size: 14px;
        }
      }
    }
  }  
}

/*End  Sidebar navigation*/

.grid-wrapper {
  width: calc(100% - 60px);
  padding-top: 8rem;
  padding-right: 2rem;
  padding-left: 6rem;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  position: relative;
}
.welcome-msg {
  margin-top: 4rem;
  margin-bottom: 2rem;
}
.welcome-msg h1 {
  margin-top: 0px;
  margin-right: 1rem;
  margin-bottom: 0px;
  font-size: 2rem;
  line-height: 2.5rem;
  font-weight: 700;
}

.content-box {
  padding: 1.5rem 1rem;
  border-radius: 1rem 1rem 1rem 0;
  background-color: #fff;
  box-shadow: 1px 1px 20px -4px rgba(0, 44, 69, 0.1),
    1px 1px 4px 0 rgba(0, 44, 69, 0.1);
  margin-bottom: 15px;
  flex: 1;
  overflow: hidden;
  position: relative;
  // min-height: 480px;
  .common-heading {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    color: #002c45;
    margin-bottom: 16px;
  }
}

.common-heading {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
}
.mr-1 {
  margin-right: 1rem;
}
.mr-15 {
  margin-right: 15px;
}

// for dashboard page
.filter-wrapper {
  .custom-dropdown {
    .dropdown {
      margin: -8px 0px 0px 12px;
    }
  }
}
.custom-dropdown {
  display: inline-block;
  .dropdown-menu.w-open {
    top: 26px !important;
    left: 14px !important;
    margin-top: 0.5rem;
    padding-bottom: 1rem;
    border-radius: 0px 1rem 1rem;
    background-color: #fff;
    box-shadow: 1px 1px 10px 0 rgba(0, 44, 69, 0.2);
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
    z-index: 1;
    .dropdown-item {
      font-size: 0.875rem;
      padding: 0.5rem 1.5rem 0.5em;
      &:hover {
        background-color: rgba(0, 44, 69, 0.1);
        outline: 0;
      }
    }
  }

  .dropdown-toggle {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 0px 1rem;
    border: 1px solid #000;
    border-radius: 4rem 4rem 4rem 0px;
  }
  .w-dropdown-toggle {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: inline-block;
    cursor: pointer;
    padding-right: 14px;
    color: #222222;
    font-size: 0.875rem;
    line-height: 1.7rem;
  }
  .dropdown-toggle.gu-dropdown-toggle {
    border: none;
    width: 36px;
    height: 36px;
    padding: 8px;
    background: rgba(0, 44, 69, 0.1);
    border-radius: 36px 36px 36px 0px;
    &:after {
      border-top: 0;
      border-right: 0;
      background-image: url("assets/images/plusIco.svg");
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      content: "";
      width: 20px;
      height: 20px;
      border-left: 0;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .dropdown-menu {
    padding-top: 1rem;
    position: absolute;
    background: #dddddd;
    display: none;
    min-width: 100%;

    .dropdown-link {
      position: relative;
      vertical-align: top;
      text-decoration: none;
      color: #222222;
      padding: 0.75rem 2rem 0.75em;
      text-align: left;
      margin-left: auto;
      margin-right: auto;
      white-space: nowrap;
      display: block;
      font-size: 0.875rem;
    }
  }
}

.stat-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 2rem;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  .stat-blocks {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    .stat-block {
      padding-right: 2rem;
      .stat-label-wrap.content-end {
        justify-content: end;
      }
      .stat-label-wrap {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        // display: flex;
        justify-content: flex-end;
        margin-bottom: 3px;
        .stat-color {
          display: inline-block;
          width: 0.5rem;
          height: 0.5rem;
          margin-right: 0.265rem;
          border-radius: 20px;
          background-color: #002c45;
        }
        .stat-color.primary {
          background-color: #04c3cb;
        }
        .stat-color.secondary {
          background-color: #f8b630;
        }
        .stat-label {
          letter-spacing: 0.001em;
          font-weight: bold;
          color: #002c45;
          opacity: 0.6;
          font-size: 0.75rem;
          line-height: 1.125rem;
        }
      }
      .trend {
        display: inline-block;
        margin-left: 0.5rem;
        .shade{
          color: #002C45;
          opacity: 0.5;
          font-size: 14px;
          line-height: 24px;
        }
      }
      .trend {
        color: #04cb9b;
        .image-7 {
          margin-right: 0.25rem;
          padding-bottom: 0.2rem;  
               
        }       
        .text-block-27 {
          display: inline;
          position: relative;
          .strend{
            font-size: 20px;           
            position: absolute;
            top: -7px;
            right: -20px;
          }
          i.fa.strend.fa-sort-asc{
            top:3px;
          }
        }
      }
      .trend.upward {
        color: #04cb9b;
      }
      .trend.downward {
        color: #f83030;
      }
      .stat-sub-line {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
      }
      .stat-number.primary {
        color: #04c3cb;
      }
      .stat-number.primary-2 {
        color: #005969;
      }
      .stat-number.secondary {
        color: #f8b630;
      }
      .stat-number {
        display: inline-block;
        color: #002c45;
        font-size: 1.5rem;
        line-height: 2rem;
        vertical-align: middle;
      }
      .stat-sub {
        display: inline;
        color: rgba(0, 44, 69, 0.6);
        line-height: 18px;
        white-space: nowrap;
        font-size: 13px;
      }
      .stat-sub.dark {
        margin-right: 0.55rem;
        color: #002c45;
      }
      .progress{
        height: 4px;
        overflow: hidden;
        background-color: rgb(0, 44, 69, 0.1);
        border-radius: 50px;
        .progress-bar{       
          width: 0%;
          height: 100%;
          background-color: #54D072;
          opacity: 0.8;
          border-radius: 50px;
        }
      }
    }

    .stat-block.right {
      margin-left: 1rem;
      padding-right: 0rem;
      text-align: right;
    }
  }
}

.subscription_text {
  position: relative;
  top: 1px;
}

.table-borderless thead th {
  border-bottom: none !important;
}
.table-borderless thead tr th{
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color:rgba(0, 44, 69, 0.6);
  vertical-align: top;
}
.table-borderless tbody tr td {
  vertical-align: middle;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.001em;
  color: rgba(0, 44, 69, 0.8);
}
.user-profile {
  display: flex;
  flex: 1;
  position: relative;
  align-items: center;
  .widget-content-left {
    margin-right: 8px;
    width: 24px;
    img {
      width: 24px;
      min-width: 24px;
      height: 24px;
      border-radius: 50%;
    }
  }
  .widget-heading {
    font-style: normal;
    font-weight: bold;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    color: rgba(0, 44, 69, 0.8);    
    min-width: 120px;
    text-transform: capitalize;
    // white-space: nowrap;
    // overflow: hidden;
    // max-width: 153px;
    // text-overflow: ellipsis;
    max-width: 145px;
  }
}

.widget {
  padding-top: 3rem;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  h2 {
    color: rgba(0, 44, 69, 0.9);
    margin-top: 0px;
    margin-right: 1rem;
    margin-bottom: 0px;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 700;
  }
}

.subscribers-wrapper {
  position: relative;
  padding: 0px 5px 15px 5px;
  height: 100%;
  overflow: auto;
  table {   
    tr{
      th.theading{
        padding: 4px;
      }
      th.view-all{
        padding: 4px;
        line-height:33px;     
        a{
          color: #54D072;
          min-width: 70px;
          &:hover{color: #54D072;}
        }
      }
      td{
        padding: 0.5rem;
      }
    }
  }
  .theading {
    font-style: normal;
    font-weight: bold;
    font-size: 1.125rem;
    line-height: 1.5625rem;
    color: #002c45;
    opacity: 0.8;
  }
  .view-all {
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: #54D072;
    text-align: right;
    &:hover{color: #54D072;}
  }
  .price {
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    text-align: right;
    color: #002c45;
    opacity: 0.6;
    text-align: right;
    // min-width: 75px;
    word-break: break-all;
    white-space: nowrap;
  }
}

table.subscribers_heading{
  margin-bottom: 5px;
  tr{
    th.theading{
      padding: 4px;
      line-height: 33px;
    }
    th.view-all{
      padding: 4px;
      line-height: 33px;
      a{
        color: #6FDDDB;
        float: right;
        min-width: 70px;
        padding-right: 12px;
        // &:hover{color: #54D072;}
      }
    }
    td{
      padding: 0.5rem;
    }
  }
  .view-all {
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: #54D072;
    text-align: right;
    &:hover{color: #54D072;}
  }
  .theading {
    font-style: normal;
    font-weight: bold;
    font-size: 1.125rem;
    line-height: 1.5625rem;
    color: #002c45;
    opacity: 0.8;
  }
}

.event-section{
  .user-profile{
    .srNumber{
      margin-right: 16px;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;
      color: #002C45;
      opacity: 0.4;
    }
    .widget-content-left{
      img{
        border-radius: 2px 2px 2px 0px; width: 36px; min-width: 36px; height: 24px;
      }
    }
    .widget-heading{
      .title{
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
        color: #002C45;
        opacity: 0.8;
        max-width: 230px;
      }
      .particepants{
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 18px;
        color: #002C45;
        opacity: 0.4;
      }
    }
  }
  .fundraised-data{
    .fund{
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;       
        text-align: right;
        color: #002C45;
        opacity: 0.6;
     }
     .total-events{
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 18px;     
      text-align: right;    
      color: #002C45;
      opacity: 0.4;
     }
  }
}

#detailedDashboardYear {
  ul.yearPicker {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 16px 7px;
    li {
      width: 33.33%;
      padding: 5px;
      text-align: center;
      list-style-type: none;
      cursor: pointer;
      color: #002c45;
      opacity: 0.8;
      &:hover {
        background: #e5e5e5;
      }
    }
    li.active{
      background: #e5e5e5; 
    }
  }
  .modal-header {
    border-bottom: none;
    .modal-title {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      color: #002c45;
    }
  }
}

#detailedDashboardMonth {
  .monthPicker {
    text-align: center;
  }
  ul.year {
    display: block;
    justify-content: space-around;
    list-style-type: none;
    width: 100%;
    float: left;
    li {
      width: 25%;
      float: left;
      padding: 5px;
      text-align: center;
      list-style-type: none;
      cursor: pointer;
      text-transform: uppercase;
      color: #002c45;
      opacity: 0.8;
      &:hover {
        background: #e5e5e5;
      }
    }
    li.disabled {
      opacity: 0.4;
    }
    li.active{
      background: #e5e5e5; 
    }
  }
  .modal-header {
    border-bottom: none;
    .modal-title {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      color: #002c45;
    }
  }
}
.view-all {
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: #6FDDDB;
  &:hover{
    color: #6FDDDB;
  }
}

.modal-content {
  border: none !important;
  border-radius: 1rem 1rem 1rem 0 !important;
  background-color: #fff;
  box-shadow: 1px 1px 20px -4px rgba(0, 44, 69, 0.1),
    1px 1px 4px 0 rgba(0, 44, 69, 0.1) !important;
}
.pl-0{padding-left: 0;}
.event-list{
  table{
    thead{
      tr{
        td{
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0.001em;
            color: rgba(0, 44, 69, 0.6);            
            padding: 5px 7px;
        }
      }
    }
    tbody{
      tr{
        td.fontStyle{
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 18px;         
          letter-spacing: 0.001em;         
          color:  rgba(0, 44, 69, 0.8);
          padding: 5px 7px;
        }
      }
    }
  }
}

.fundraiser-stories{
  table thead tr{
    td{
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #002C45;
      opacity: 0.6;     
    }
  }
  table tbody tr{
    td{
      vertical-align: top !important;
      p{
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: #002C45;
        opacity: 0.6;
        margin-bottom: 0;
      } 
    }
    td.story{
      width: 45%;
    }
    td{
      ul.focus{
        padding:0;
        margin:0;
        list-style-type: none;
        li{
          a{           
            background: #CDF3F5;
            text-align: center;
            padding: 7px 10px;
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 14px;
            text-align: center;
            color: rgba(0, 44, 69, 0.6);
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 140px;
            min-width: 80px;
            cursor: default;
            border-radius: 10px 12px 12px 0px;
            letter-spacing: 0.01em;
          }
        }
      }
    }
    td.date{
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 140%;
      text-align: right;
      letter-spacing: 0.001em;
      color: #002C45;
      opacity: 0.8;
      padding-right: 30px;
    }
  }
}
.updatedText {  
  padding-top: 14px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #002C45;
  opacity: 0.6;
}
.NotFound, .notFound{
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #002C45;
  opacity: 0.6;
  padding: 16px 0;
  text-transform: capitalize;
}
.nofound{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;  
  p{
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #002C45;
      opacity: 0.6;       
      text-transform: capitalize;
      margin-bottom: 16px;
  }
}
// for breadcrumb
.breadcrumbs{
  position: absolute;   
  top: -45px;
  left: -50px;
  ul{
      list-style-type: none;
      li{
        display: inline-block;
        margin-right: 2px;
        position: relative;
        background: #F2F4F5;
        border-radius: 0px 24px 24px 0px;
        &:after{
          content: "";
          width: 30px;
          height: 30px;
          position: absolute;               
          border-radius: 50%;
          box-shadow: 0px 10px 0 0 #f0f3f5;
          transform: rotate(-90deg);
          left: -30px;
          top:0;
        }
        &:first-child{
          &:after{
            border-radius: 0;                
          }
        }
        a{          
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 17px;
          display: flex;
          align-items: center;
          color: #B3C0C8;
          //opacity: 0.5;        
          height: 30px;
          padding: 4px 14px;
          position: relative;   
          z-index: 9;      
        }
        &:first-child{  
            a{
              padding-left: 52px;
              &:after{
                border-radius: 0;                
              }
            }
        }
      }
      li.active{
          background: #EBEEF0;         
          &:after{
            box-shadow: 0px 10px 0 0 #EBEEF0;
          }
          a{
              opacity: 0.8;
              color: #002C45;
              cursor: default;
          }
      }
  }
}

// for new events page
.form-content{        
  background: #FFFFFF;
  box-shadow: 0px 4px 36px rgba(0, 44, 69, 0.08), 0px 4px 16px rgba(0, 44, 69, 0.04), 0px 4px 4px rgba(0, 44, 69, 0.1);
  border-radius: 16px 16px 16px 0px;
  margin-bottom: 15px;
  padding:32px 33px;
}
.form-wrapper{
  .col-form-label{
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 44, 69, 0.6);    
  }
  .form-control{
    background: rgba(0, 44, 69, 0.04);
    border: 1px solid rgba(0, 44, 69, 0.08);
    box-sizing: border-box;
    border-radius: 0px 24px 24px 16px;
    -webkit-border-radius: 0px 24px 24px 16px;
    -moz-border-radius: 0px 24px 24px 16px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.001em;
    color: #002C45;
    opacity: 0.8;
    max-width: 360px;
    height: calc(1.8em + .75rem + 2px);
  }
  .wt-148{
    max-width: 148px;
  }
  .wt-198{
    max-width: 198px;
  }
  .wt-240{
    max-width: 240px;
  }
  .wt-320{
    max-width: 320px;
  }
  .form-group{
    margin-bottom: 1.5rem;
  }
  input,select::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #002C45;
    opacity: 0.4;
  }
  input,select::-moz-placeholder { /* Firefox 19+ */
    color: #002C45;
    opacity: 0.4;
  }
  input,select:-ms-input-placeholder { /* IE 10+ */
    color: #002C45;
    opacity: 0.4;
  }
  input,select:-moz-placeholder { /* Firefox 18- */
    color: #002C45;
    opacity: 0.4;
  }
  .repeater{
    position: relative;
    .addMore{
      border: none;
      width: 36px;
      height: 36px;
      padding: 8px;
      background: rgba(0, 44, 69, 0.1);
      border-radius: 36px 36px 36px 0px;
      position: absolute;
      left: 340px;
      bottom: 0;
      &:after{
        border-top: 0;
        border-right: 0;
        background-image: url('assets/images/plusIco.svg');
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        content: "";
        width: 20px;
        height: 20px;
        border-left: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      &:hover{
        background-color: #54D072;       
        color: #ffffff;
      }
    }
  }
}
body .ui-button{
  background-color: #5cc4cb !important;
  border: 1px solid #5cc4cb !important;
  font-size: 'Poppins', sans-serif !important;
}
body .pi{
  font-size: 14px !important;
}
body .ui-fileupload .ui-fileupload-content{
  border:none !important;
}
body .ui-fileupload .ui-fileupload-buttonbar{
  border-bottom: 1px solid #c8c8c8 !important;
}
body .ui-progressbar{
  background: none !important;
}
body .md-drppicker .btn,.custom-btn{
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
  background: #54D072;
  border-radius: 16px 16px 16px 0px;
  padding: 11px 18px;
  border:0;
  text-align: center;
  outline: 0;
  cursor: pointer;
  min-width: 96px;
  &:hover{
    background-color: #54D072;
    -webkit-transform: translate(-2px, -2px);
    -ms-transform: translate(-2px, -2px);
    transform: translate(-2px, -2px);
    color: #ffffff;
  }
}
.custom-heading{
  margin-bottom: 30px;
  h6{
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    color: #002C45;
  }
  p{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.001em;
    color: #002C45;
    opacity: 0.5;
    margin-bottom: 0;
  }
}
// for Header
.fa.fa-angle-down {color: #ffffff;}
.app-header.header-shadow.stickyHeader{position: fixed;}
.app-header {
  position: absolute;
  width: 100%;
  top: 0;
  height: 60px;
  display: flex;
  align-items: center;
  align-content: center;
  z-index: 99;
  transition: all 0.2s;
  background: #000000;
  border-radius: 0 0 2.5em 0;

  .user-widget-content {
    margin-left: 1rem;
    margin-right: 1rem;
    display: flex;
    flex: 1;
    justify-content: flex-end;
    position: relative;
    .dropdown-toggle::after {
      border-top: none;
      border-right: none;
    }
    .btn-group {
      .btn{
        background: transparent !important;
      }
      .dropdown-menu {
        top: 40px !important;
        left: -170px !important;
        padding: 1rem;
        background: #000000;
        border: none;
        border-radius: 0.75rem 0rem 0.75rem 0.75rem;
        box-shadow: 1px 1px 20px -4px rgba(0, 44, 69, 0.1),
          1px 1px 4px 0 rgba(0, 44, 69, 0.1);
        max-width: 201px;
        min-height: 140px;  
        .dropdown-item {
          padding: 0;
          width:160px;
          .user-details {
            list-style: none;
            padding: 0;
            margin: 0;
            li {
              margin-bottom: 1rem;
              &:last-child{margin-bottom: 0;}
              a {
                font-size: 14px;
                color: #54D072;
                cursor: pointer;
                &:hover {
                  color: #54D072;
                }
                img {
                  padding-right: 8px;
                }
              }
              a.setting span {
                padding-left: 4px;
                display: inline-block;
                vertical-align: middle;
                padding-top: 5px;
                font-weight: 700;
              }
              .username {
                font-size: 14px;
                font-weight: 700;
                margin-bottom: 0;
                letter-spacing: 0.001em;
                color: #ffffff;
                white-space: initial;
                word-break: break-word;
              }
              .useremail {
                font-size: 14px;
                font-weight: 400;
                margin-bottom: 0;
                letter-spacing: 0.001em;
                color: #ffffff;
                opacity: 0.9;
                white-space: initial;
                word-break: break-word;
              }
            }
          }
          &:hover {
            background: none;
          }
        }
      }
    }
  }
}
.app-header.header-shadow {
  box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03),
    0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03),
    0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05),
    0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
}

.dropdown-item.active, .dropdown-item:active{
  background: rgba(0, 44, 69, 0.04) !important;
  border-radius: 0px 24px 24px 0px;
  width: 128px;
}

.loginBg {
  background-image: url("/assets/images/background.svg") !important;
  background-repeat: no-repeat;
  background-color: #ffffff;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100vh;
  position: relative;
  .loginBGbox {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    background: #000000;
    z-index: 9;
    padding-top: 190px;
    .while-logo {
      padding-bottom: 2rem;
     
    }
    p {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 140%;
      display: block;
      align-items: center;
      text-align: center;
      letter-spacing: 0.001em;
      color: #54D072;
      opacity: 1;
      padding-top: 1rem;
      a {
        padding-left: 0px;
        color: #6FDDDB;
        border-bottom: 1px solid #6FDDDB;
      }
    }

    .login-footer {
      position: absolute;
      bottom: 0;
      width: 100%;
      text-align: center;
      margin-bottom: 2rem;
      p {
        letter-spacing: 0.001em;
        color: #54D072;
        opacity: 1;
        font-size: 14px;
        margin-bottom: 0.3rem;
        font-family: 'Poppins', sans-serif;
        a {
          color: #6FDDDB;
          &:hover {
            border-bottom: 1px solid #6FDDDB;
          }
        }
      }
    }
    .btn-facebook:hover {
      opacity: 0.8;
    }
    
    .guarrowImg {
      position: absolute;
      background-image: url("/assets/images/guarrow.png") !important;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: left top;     
      width: 898px;
      height: 734px;
      top: -121px;
      left: 0;
      z-index: -1;
    }
    
  }
}


#style-2::-webkit-scrollbar {
  width: 12px;
  // background-color: #F5F5F5;
}

#style-2::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(4, 195, 203, 0.3);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  background-color: #f5f5f5;
}

#style-2::-webkit-scrollbar-thumb {
  background-color: #04c3cb;
  width: 7px;
  box-shadow: inset 0 0 1px 1px #ffffff;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
}

.rt-25{right: 25%;}

.app-header__logo{
    margin-left: 1.5rem;
    .navbar-toggle{
        border: none;
        background: transparent;
    }
}
.navbar-toggle.x.collapsed .icon-bar:nth-of-type(1) {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
}
.navbar-toggle.x.collapsed .icon-bar:nth-of-type(2) {
    opacity: 1;
    filter: alpha(opacity=100);
}
.navbar-toggle.x.collapsed .icon-bar:nth-of-type(3) {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
}
.navbar-toggle.x .icon-bar:nth-of-type(3) {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transform-origin: 10% 90%;
    -ms-transform-origin: 10% 90%;
    transform-origin: 10% 90%;
}
.pen .navbar-toggle .icon-bar {
    width: 22px;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
}
.navbar-toggle .icon-bar {
    background-color: #002C45;
}
.navbar-toggle .icon-bar+.icon-bar {
    margin-top: 4px;
}
.navbar-toggle .icon-bar {
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
}

.navbar-toggle.x .icon-bar:nth-of-type(1) {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transform-origin: 10% 10%;
    -ms-transform-origin: 10% 10%;
    transform-origin: 10% 10%;
}
.navbar-toggle.x .icon-bar:nth-of-type(2) {
    opacity: 0;
    filter: alpha(opacity=0);
}
.navbar-toggle.x .icon-bar:nth-of-type(3) {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transform-origin: 10% 90%;
    -ms-transform-origin: 10% 90%;
    transform-origin: 10% 90%;
}
.svg-funnel-js:not(.svg-funnel-js--vertical) .svg-funnel-js__label:not(:first-child) {
  border-left: 1px solid rgba(0, 44, 69, 0.2) !important;
}
.min-120{min-width: 120px;}
.search-15{margin-right: 15px;}
.md-drppicker .clear svg{display: none !important;}
.md-drppicker td.off { background-color: #fff !important; pointer-events: none; color: #bbb !important; }
.md-drppicker{font-family: 'Poppins', sans-serif !important;}
.ngo-grid-wrapper{
  width: calc(100% - 298px);
  padding-top: 8rem;
  padding-right: 32px;
  padding-left: 48px;
  -ms-grid-row-align: center;
  align-self: center;
  flex: 1;
  position: relative;
}
.ngo-grid-wrapper{
  .content-box{
      padding:32px 20px;
      table{
        tr{
          th{
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color:rgba(0, 44, 69, 0.8);
          }
        }
        tr{
          td{
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.001em;
            color:rgba(0, 44, 69, 0.8);
          }
        }
        tr{
          td.fb-page{ 
            a{
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 20px;
              letter-spacing: 0.001em;
              color: #04C3CB;
              word-break: break-word;
            }       
          }
        }
      }
  }
  }
  
  .connect-switch{
  input{
    opacity: 0;
    width: 0;
    height: 0;
  }
  position: relative;
  display: inline-block;
  width: 34px;
  height: 14px;
  .slider.round {
    border-radius: 34px;
    &:before{
      border-radius: 50%;
    }
   }   
   .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 44, 69, 0.2);
    -webkit-transition: .4s;
    transition: .4s;
    &:before{
      position: absolute;
      content: "";
      height: 20px;
      width: 20px;
      left: 0px;
      bottom: -3px;
      background-color: #002C45;
      -webkit-transition: .4s;
      transition: .4s;
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    }
   }
    input:checked + .slider {
      background-color: #54D072;
    }  
    input:focus + .slider {
      box-shadow: 0 0 1px #54D072;
    }  
    input:checked + .slider:before {
      -webkit-transform: translateX(14px);
      -ms-transform: translateX(14px);
      transform: translateX(14px);
    }  
  }
  
  .connect-secondary-switch{
  input{
    opacity: 0;
    width: 0;
    height: 0;
  }
  position: relative;
  display: inline-block;
  width: 36px;
  height: 24px;
  .slider.round {
    border-radius: 12px 12px 12px 0px;
    &:before{
      border-radius: 50%;
    }
   }   
   .slider {  
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 44, 69, 0.2);;
    -webkit-transition: .4s;
    transition: .4s;
    &:before{
      position: absolute;
      content: "";
      height: 20px;
      width: 20px;
      left: 2px;
      bottom: 3px;
      background:#002C45;
      box-shadow: 1px 1px 0px rgba(0, 44, 69, 0.2);
      -webkit-transition: .4s;
      transition: .4s;
    }
   }
    input:checked + .slider {
      background-color:#54D072;
    }  
    input:focus + .slider {
      box-shadow: 0 0 1px #54D072;
    }  
    input:checked + .slider:before {
      -webkit-transform: translateX(12px);
      -ms-transform: translateX(12px);
      transform: translateX(12px);
    }  
  }
  .ngo-btn-wrapper{
    display: inline-block;
    float: right;
  }
  .max-wt-380{max-width: 380px;}
  .input-group-btn{position: absolute; top: 3px; right: 20px;}
  .input-group-btn .custom-btn{padding: 5px 17px; height: 28px;}
  .pr-80{padding-right: 80px;}
  .ngo-admin-box .breadcrumbs{
    left: -340px;
  }
  .ngo-admin-box .breadcrumbs ul li:first-child a {
    padding-left: 340px;
  }
  .ngo-admin-heading {
    p{
      font-size: 14px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.001em;
      color: #002C45;
      opacity: 0.5;
    }
  }
  .ngo-detail-wrapper{
    display: flex;
    flex-direction: row;
    align-items: top;
    .imgBox{
      margin-right: 16px;
      img{
        width: 50px;
      }
    }
    .ngo-heading{
      h2{
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 40px;
        color: #002C45;
        opacity: 0.8;
      }
      a{
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.001em;
        color: #04C3CB;
      }
    }
  }
.mat-sort-header-arrow{opacity: 1 !important; transform: translateY(3px) !important;}
.mat-sort-header-stem{width: 0 !important; height: 0 !important;}
.mat-sort-header-arrow, [dir=rtl] .mat-sort-header-position-before .mat-sort-header-arrow{margin: 0 0 0 4px !important;}
[aria-sort=descending] .mat-sort-header-container.mat-sort-header-sorted .mat-sort-header-arrow{transform: translateY(-4px) !important;}
[aria-sort=ascending] .mat-sort-header-container.mat-sort-header-sorted .mat-sort-header-arrow{transform: translateY(3px) !important;}
.reset{
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;  
  padding: 8px 17px;
  background: #002C45;
  border-radius: 200px 200px 200px 0px;
  margin: 0px 0px;
  border: 0;
  text-align: center;
  outline: 0;
  &:hover{
    background-color: #002C45;
    -webkit-transform: translate(-2px, -2px);
    -ms-transform: translate(-2px, -2px);
    transform: translate(-2px, -2px);
  }
}
.logoText{
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #002C45;
  opacity: 0.8;
  position: relative;
  margin-left: 1rem;
  display: none;
}  
.lgFont{          
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;   
}
.prBg{background-color: #00A8AE;}
.newBg{background-color: #318DE2;}
.prColor{color:#04C3CB;}
.orBg{background-color:#F8B630;}
.orColor{color:#F8B630;}
.vlBg{background-color:#755DB9;}
.vlColor{color: #755DB9;}
.blColor{color: #002C45;}
.frgraphLegend{margin-left: 60px;}
.frgraphLegendRight{margin-right: 40px; float: right;}
.legends{
  list-style: none;  
  margin-bottom: 5px;
  display: block;  
  li{
    cursor: pointer;
    list-style: none;
    display: inline-block;
    vertical-align: middle;
    margin-right: 22px;
    .legendBox{
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      .lgDisc{
        width: 6px;
        height: 6px;
        border-radius: 50%;
        margin-top: -3px;
      }
      .lgText{     
          color: rgba(0, 44, 69, 0.6);
      }
      .Line{
        position: relative;
        height: 1px;
        background: #000000;      
        width: 8px;
        vertical-align: middle;
        display: table-cell;
        // &:before{
        //   content:"";
        //   position: absolute;
        //   width: 8px;    
        //   height: 1px;
        //   background-color: #000000;
        //   left: -4px;
        //   top: 0px;
        // }        
      }
    }
    &:last-child{
      margin-right: 0;
    }
  }
}
// .md-drppicker .calendar.right tr:first-child th{cursor: default !important; pointer-events: none !important;}
.donutList{
  overflow-y: auto;
  max-height: 205px;
  ul{
    list-style-type: none;
    padding:0;
    margin:20px 5px 0 0;
    li{
      list-style: none;
      display: block;
      margin-bottom: 12px;
      margin-left: 20px;
      position: relative;
      .disc{
        position: absolute;
        width: 11px;
        height: 11px;
        display: block;
        border-radius: 50%;   
        left:-20px;
        top:2px;     
      }
    }
  }
}
.commonFont{
  font-style: normal;  
  font-size: 14px;
  line-height: 17px;
  color: #002C45;
  opacity: 0.8;
}
.eventType{
  ul{
    list-style-type: none;
    padding:0;
    display: flex;
    justify-content: space-around;
    flex-flow: row wrap;
    padding:16px 24px 0 24px;
    max-height: 75px;
    overflow-y: auto;
    li{
      list-style: none;
      display: inline-block;
      margin-bottom: 12px;
      position: relative;
      .sdisc{
        position: absolute;
        width: 6px;
        height: 6px;
        display: block;
        border-radius: 50%;   
        left:-10px;
        top:5px;     
      }
    }
  }
}
.greyBg{background-color:#002C45;}
.lgreyBg{background-color:#8095A2;}
body .ui-widget{font-family: 'Poppins', sans-serif !important;}
.ui-fileupload-files{
  word-break: break-all ;
}
body #addOrganization .ui-fileupload .ui-fileupload-content{padding:0.571em 0 !important;}
#addOrganization .ui-fileupload-row>div{padding:.5em .5em !important;}
body .pure-checkbox{padding:5px 10px !important;}
body .lazyContainer{max-height: 100px !important;}
body .pure-checkbox input[type=checkbox]+label{
  font-family: 'Poppins', sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 16px !important; 
  letter-spacing: 0.001em;
  color: #002C45 !important;
  opacity: 0.8 !important;
}
body .manage-organization .ui-fileupload .ui-fileupload-buttonbar{
  background-color: transparent !important;
  padding: 0.571em 1em;
  border: none !important;
  color: #ffffff;
  border-bottom: 0 none;
}
body .manage-organization .drag-n-drop .ui-fileupload .ui-fileupload-buttonbar .ui-button{
  width: 100%;
  min-height: 150px;
  background-color: transparent !important;
  margin-right: 0;
  border: none !important;
  color: transparent !important;
}
body .manage-organization .ui-fileupload-choose input[type=file]{
  display: inline-block;
}
.close-btn{
  width: 24px;
  height: 24px;
  right: 14px;
  position: absolute;
  top: 12px;
  background: rgba(0, 44, 69,0.12);
  border-radius: 30px 30px 30px 0px;
  border:none;
  span{
    color: #000;
  }  
}
.browseBtn {
  .ui-fileupload-choose{
    background: #04C3CB !important;
    border-radius: 16px 36px 36px 0px !important;   
  }
  .ui-button-icon-left{
    display: none !important;  
  }
  span.ui-button-text.ui-clickable{
    padding: 11px 14px !important;
  }
}
.sideContent{
  position: -webkit-sticky;
  position: sticky;
  top: 15px;
  height: 94vh;
  // min-height: 930px;
}
@media (min-width: 992px) {
  .colFull{
    width: calc(100% - 270px);
    position: relative;
    margin-right: 18px;
  }
  .col-240{
    width: 240px;    
    margin-top: 110px;    
  }
  .sidebar{
    .logo-normal{
      p{display: none;}
    }
    .organisation{
      .btn-group{
        .dropdown-toggle{
          .orgName{
            display: none;
          }
        }
      }  
    }
    .dropdown-menu.show {
      display: none;
    }
    &:hover {
      width: 260px;
      padding-right: 1rem;
      .logo-normal {
        p {
          display: block;
        }
      }
      h6 {
        // display: block;
        visibility: visible;
      }
      .text-block-28 {
        text-indent: 0rem;
      }
      .organisation {
        .btn-group {
          .dropdown-toggle {
            .orgName {
              display: block;
            }
          }
        }
        .orgName {
          display: block;
        }
      }
  
      .dropdown-menu.show {
        display: block;
      }
    }
  }
  .closebtn{display: none;}
  .userSidebarWrapper{
    .userSidebar{
      width: 240px;
    }
  }
  .navbar-header{display: none;}
  .mx-864{max-width: 864px;}
}

@media(max-width:1440px){
  .loginBg .loginBGbox .guarrowImg{
      width: 966px;
      height: 795px;
      top: -155px;
  }
}


@media(max-width:1366px){
  .loginBg .loginBGbox .guarrowImg{
    width: 895px;
    height: 725px;
    top: -110px;
  }
}

@media (max-width: 1280px){
  .loginBg .loginBGbox .guarrowImg {
    width: 886px;
    height: 732px;
    top: -132px;
 } 
}


@media(max-width:1024px){
  .loginBg .loginBGbox .guarrowImg {
      width: 758px;
      height: 675px;
      top: -140px;
  }
  .loginBg .loginBGbox{
    padding-top: 160px;
  }
}

@media (max-width: 991px) {
  .event-details-box{
    padding:18px 0px 4px 0px !important;
  }
  .breadcrumbs{
    top:-10px;
  }
  .mbTitle{margin-top: 50px;}
  .colFull, .col-240{width: 100%; position: relative; margin-right: 0;}
  .sidebar.closed-sidebar{
    display: none;
  }
  .sidebar.open-sidebar{
    display: block;
  }
  .sidebar {   
    width: 260px;
    padding-right: 1rem;
    border-bottom-right-radius: 5em;
    display: none;
    h6 {
      display: block;
    }
    .text-block-28 {
      text-indent: 0rem;
    }
  }
  .content-box {
    height: auto;
    min-height: auto;
  }
  .full {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .grid-wrapper {
    padding-top: 6rem;
    padding-left: 2rem;
    padding-bottom: 1.5rem;
    width: 100%;
  }
  .mr-15 {
    margin-right: 0;
  }

  .subscribers-wrapper {
    table {
      position: relative;
      top: 0px;
      height: 100%;
      overflow-y: scroll;
      tr{
        th.theading{
          padding: 4px;
        }
        th.view-all{
          padding: 4px;
        }
      }
    }
  }
  .closebtn{
    position: absolute;
    font-size: 24px;
    color:#ffffff !important;
    top:4px;
    right:0px;
    cursor: pointer;
    width: 24px;
    height: 24px;
    text-align: center;
    display: block;
  }
  .user-profile .widget-heading{
    max-width: 200px;
  }
  .form-wrapper{
    .form-group{
      margin-bottom: 1rem;
    }
  }
  .loginBg .loginBGbox{
    padding-top: 0;
    height: 400px;
  }
  .loginBg .loginBGbox .guarrowImg {
   background-image: none !important;
  }
  .userSidebarWrapper{
    .userSidebar{
      width: 240px;
      position: fixed;
      z-index: 9999;
      display: flex;
      height: 100vh;
      padding-top: 1rem;
      flex-direction: column;
      border-bottom-right-radius: 12em;
      background-color: #ffffff;
      border-bottom-right-radius: 5em;   
      box-shadow: 1px 1px 20px -4px rgba(0, 44, 69, 0.1), 1px 1px 4px 0 rgba(0, 44, 69, 0.1);
      display: none;
    }
    .sidebar .side-nav-menu ul li a ul {display: none;}
  }
  .userSidebarWrapper .userSidebar.open-sidebar{
    display: block;
  }
  .userSidebarWrapper .userSidebar.closed-sidebar{
    display: none;
  }
  .mt-44{
    margin-top:44px;
  }
  .logoText{
    display: block;
  }
  .sideContent{
    overflow: inherit;
    top:0;
    min-height: auto;
  }

}

@media( max-width : 767px){
  .frgraphLegendRight{margin-left: 0;}
  .legends li{margin-right: 10px;}
  .md-drppicker .calendar td, .md-drppicker .calendar th{
    min-width: 30px !important;
  }
}

@media( max-width : 576px){
  .content-box{
    padding: 1.5rem;
  }
  .stat-row{
    display: block;
    .stat-blocks{
       margin-bottom: 1.5rem;      
      .stat-block{
        padding-right:0;      
      }
      .stat-block.right.mob2{
        margin-left: 0;
        text-align: left;
        .stat-label-wrap{
          justify-content: left;
        }
      }
    } 
  }
  .stat-row.conversions{
    display: flex;
  }
  .form-wrapper {
    .wt-320{
      max-width: 240px;
    }   
   }
  .repeater{
    .addMore{
      left: 255px;
    }
  }

  .title-wrapper{
    display: block;   
    .left-column{
      width: 100%;
    }
    .right-column{
      width: 100%;
      .btn-box{
        text-align: left;
        margin-bottom: 20px;
      }
    }
  }

  .loginBg .loginBGbox .while-logo{
    img{
      width: 300px;
    }
  }

  .frgraphLegend{margin-left: 30px;}   
  .subscribers-wrapper{overflow: hidden; padding-top: 10px; padding-bottom: 25px; }
}

@media( max-width : 375px){
  .stat-row.conversions{
    display: block;
  }
  .user-profile .widget-heading{
    max-width: 153px;
  }
  .label__value{top:33px;}
}
@media (min-width: 564px){
  .md-drppicker.ltr .calendar.left .calendar-table {
    padding-right: 18px !important;
  }
}



.cross-line {
  text-decoration: line-through;
}
.manage-organization span.ui-messages-icon {
  display: none !important;
}
body .ui-messages .ui-messages-close:focus{
  box-shadow: 0 0 0 0.2em transparent !important;
}
.manage-organization .ui-messages-error{display: none !important;}
.manage-organization .ui-fileupload-row button{display: none;}
body .mat-button{
  font-family: 'Poppins', sans-serif !important;    
  font-size: 14px; 
  font-weight: 700; 
  border-top: none; 
  line-height: 24px;
  color: #002c45;
  opacity: 0.8;
}
body .alert-danger,body .alert-success,body .alert-warning{margin-top: 4px; padding:4px 16px; border-radius: 0px 24px 24px 16px; font-family: 'Poppins', sans-serif !important;}
.common-subheading-price{
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #002C45;
  opacity: 0.8;
}
.form-wrapper.full-width-wrapper .form-control{max-width: 100%;}
.form-wrapper.full-width-wrapper .textarea_wrapper .form-control{background: transparent; border: none; margin-top: 5px;}
.form-wrapper.full-width-wrapper .textarea_wrapper .form-control:focus{box-shadow:none;}
.form-wrapper.full-width-wrapper .textarea_wrapper .form-control:focus{box-shadow:none;}
.cursor-pointer{cursor: pointer;}
.cursor-pointer:hover{background: #efefef;}
.imgShadow{
  box-shadow: 1px 1px 20px 0px rgb(0 44 69 / 10%), 4px 4px 0px 0 rgb(0 44 69 / 20%);
  -moz-box-shadow: 1px 1px 20px 0px rgb(0 44 69 / 10%), 4px 4px 0px 0 rgb(0 44 69 / 20%);
  -webkit-box-shadow: 1px 1px 20px 0px rgb(0 44 69 / 10%), 4px 4px 0px 0 rgb(0 44 69 / 20%);
}
.duration .form-control:disabled, .form-control[readonly]{background: rgba(0, 44, 69, 0.04) !important; border: 1px solid rgba(0, 44, 69, 0.08) !important; opacity: 0.8 !important;}
.label__segment-percentages{display: none;}
.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__title{color: #7F95A1 !important; font-size: 12px; line-height: 17px; font-weight: 700;}
.svg-funnel-js .svg-funnel-js__container{margin-top: 26px; margin-left: 0px;}
.common_conversion_style{
  color: #04CB9B; font-weight: 700; font-size: 12px; vertical-align: middle; padding-left: 10px; display: inline-block; margin-bottom: 3px;
}
.email_percent{ top:100px;}
.opt_in_percent{ top:100px;}
.svg-funnel-js:not(.svg-funnel-js--vertical) .svg-funnel-js__label{padding-left: 0px;}
.svg-funnel-js__label.label-2,.svg-funnel-js__label.label-3{padding-left: 12px !important;}
.funnel-data-container{
  position: absolute; top: 0px;
  .stat-blocks1{ position: absolute; top:133px;  }
  .stat-blocks2{ position: absolute; top:133px;  }
  .stat-blocks3{ position: absolute; top:133px;  }
  .common{ color: #7F95A1; font-family: Roboto; font-style: normal;font-weight: normal;
    font-size: 12px; line-height: 19px;  span{margin-left: 5px;}
  }
  .skyblue{color: #04C3CB;}
  .orange{color: #F8B630;}
}
.padTop-95{padding-top: 95px !important;}
.exportCalendarOnModal{
  .md-drppicker{
    margin-top: 10px !important;
  }
}
.modal{z-index: 9 !important;}
.modal-backdrop{z-index: 1 !important;}

.modal-open1{
  overflow: hidden !important;
  .modal{overflow: auto !important;}
}
.reSetooltip{font-weight: 700; font-size: 12px; font-family: 'Poppins', sans-serif;}
// #exportSelectedModal button, #exportSelectedModal input,#exportSelectedModal .md-drppicker.shown,
// #datePickerModal button, #datePickerModal input, #datePickerModal .md-drppicker.shown
// {
//   pointer-events: visible !important;
// }
body .md-drppicker button.btn.btn-default.clear:hover{color: #54D072 !important;}
.BSDatePicker bs-daterangepicker-inline-container{margin-top: 15px !important;}
.BSDatePicker .bs-datepicker-head{text-align: center !important;}
.BSDatePicker .theme-green .bs-datepicker-head{background-color: #ffffff !important;}
.BSDatePicker .bs-datepicker-head button{color: #002C45 !important; opacity: 0.6 !important;}
.BSDatePicker .theme-green .bs-datepicker-body table td span.selected,.BSDatePicker .theme-green .bs-datepicker-body table td.selected span,.BSDatePicker .theme-green .bs-datepicker-body table td span[class*="select-"]:after, .theme-green .bs-datepicker-body table td[class*="select-"] span:after{background-color:#54D072 !important; border-radius: 50px 50px 50px 0px !important;}
.BSDatePicker .bs-datepicker-body table td span[class*="select-"],.BSDatePicker .bs-datepicker-body table td[class*="select-"] span{border-radius: 50px 50px 50px 0px !important;}
.BSDatePicker .bs-datepicker-body table td{
  border: 1px solid transparent;
  white-space: nowrap;
  cursor: pointer;
}
.BSDatePicker .bs-datepicker-body table.days span.in-range{
  background-color: #dde2e4 !important;
  //border: 1px solid #ffffff !important;
  border-radius: 0 !important;
}
.BSDatePicker .bs-datepicker-body table td span{
  border-radius: 0 !important;
  width: 35px !important;
  height: 28px !important;
  line-height: 28px !important;
}
.BSDatePicker .bs-datepicker-body table.days span.in-range:not(.select-start):before{
  background-color: #dde2e4 !important
}
.BSDatePicker .bs-datepicker-body table td span:before{
  position: static !important;
  top: 0px !important;
  bottom: 0px !important;
  left: 0px !important;
  right: 0px !important;
}
.BSDatePicker .bs-calendar-container{
  border-radius:4px; padding-bottom: 30px;
}
.BSDatePicker .bs-datepicker-container{padding: 0px !important;}
.BSDatePicker .bs-datepicker-body table td.is-other-month span{background-color: #ffffff !important;}
.BSDatePicker .bs-datepicker-body{border: 0px solid transparent !important;}
// .BSDatePicker .bs-datepicker-head button:hover{background-color: #dde2e4 !important;}
.BSDatePicker .bs-datepicker-head button.current:hover{background-color: #ffffff !important; cursor: not-allowed !important; pointer-events: none !important;}
.BSDatePicker .bs-datepicker-head button.current{padding:  0px !important; pointer-events: none !important;}
.exportBtn button {
  position: absolute; 
  z-index: 9; 
  bottom: 25px; 
  right: 30px;
}
.exportBtn.exportPos button{bottom: 10px; right: 15px;}
// .exportBtn button:hover{background-color: #f8b630; transform: translate(-2px, -2px); color: #ffffff;}
.BSDatePicker .button-wrapper{margin-right: 0px !important;}
.BSDatePicker .bs-datepicker-body table td{color: #000000 !important; opacity: 0.8; font-family: 'Poppins', sans-serif !important;}
.BSDatePicker .bs-datepicker-body table th{color: #002C45 !important; opacity: 0.6;}
.BSDatePicker .bs-datepicker-head button.next span, .BSDatePicker .bs-datepicker-head button.previous span{font-size: 26px !important;}
.BSDatePicker .bs-datepicker-buttons{border-top: 1px solid transparent !important;}
.BSDatePicker .bs-datepicker{border-radius: 4px !important;}
.BSDatePicker .bs-datepicker-head button.previous{position: absolute; left: 14px;}
.BSDatePicker .bs-datepicker-head button.next{position: absolute; right: 14px;}
body .md-drppicker button.btn.btn-default.clear:hover{color: #54D072 !important;}
.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__value{display: none !important;}
.ctotalv{color: #002C45; font-size: 24px; line-height: 28px; font-weight: 700; position: absolute; top: 93px;}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #002C45;
}

.mat-tooltip {
  font-size: 12px !important;
  background: #002C45;
  border: 1px solid #002C45;
  border-radius: 4px;
  -webkit-border-radius:4px;
  -moz-border-radius:4px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  position: relative;
  &::after {
    width: 0;
    height: 0;
    content: '';
    position: absolute;
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
    border-bottom: 0.5rem solid #002C45;
  }
  &.below {
    overflow: initial;
    margin-top: 1rem;
    &:after {
      top: -0.5rem;
      right: calc(50% - 0.5rem);
      transform: rotate(0);
    }
  }

  &.above {
    overflow: initial;
    margin-bottom: 1rem;
    &:after {
      bottom: -0.5rem;
      right: calc(50% - 0.5rem);
      transform: rotate(180deg);
    }
  }

  &.right {
    overflow: initial;
    margin-left: 1rem;
    &:after {
      left: -0.75rem;
      top: calc(50% - 0.25rem);
      transform: rotate(270deg);
    }
  }

  &.left {
    overflow: initial;
    margin-right: 1rem;
    &:after {
      right: -0.75rem;
      top: calc(50% - 0.25rem);
      transform: rotate(90deg);
    }
  }
}
.cdk-overlay-dark-backdrop{pointer-events: none !important; background: #000000 !important; opacity: 0.5 !important;}
// .cdk-global-overlay-wrapper, .cdk-overlay-container{pointer-events: unset !important;}
.custom-dialog-container{
  .mat-dialog-container{height: 205px !important; overflow: hidden;}
}
.confirmTitle{font-family: 'Poppins';font-style: normal;font-weight: 700;font-size: 24px;line-height: 32px;text-align: center;color: #33566A;}
.table-striped tbody tr:nth-of-type(odd){background-color: rgba(255,255,255,1) !important;}
.table-striped tbody tr:nth-of-type(even){background-color: rgba(227,236,240, 0.3) !important;}
.arrow-up {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #002C45;
}
.mat-tooltip {
  font-size: 12px !important;
  background: #002C45;
  border: 1px solid #002C45;
  border-radius: 4px;
  -webkit-border-radius:4px;
  -moz-border-radius:4px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  position: relative;
  word-break: break-word;
  &::after {
    width: 0;
    height: 0;
    content: '';
    position: absolute;
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
    border-bottom: 0.5rem solid #002C45;
  }
  &.below {
    overflow: initial;
    margin-top: 1rem;
    &:after {
      top: -0.5rem;
      right: calc(50% - 0.5rem);
      transform: rotate(0);
    }
  }

  &.above {
    overflow: initial;
    margin-bottom: 1rem;
    &:after {
      bottom: -0.5rem;
      right: calc(50% - 0.5rem);
      transform: rotate(180deg);
    }
  }

  &.right {
    overflow: initial;
    margin-left: 1rem;
    &:after {
      left: -0.75rem;
      top: calc(50% - 0.25rem);
      transform: rotate(270deg);
    }
  }

  &.left {
    overflow: initial;
    margin-right: 1rem;
    &:after {
      right: -0.75rem;
      top: calc(50% - 0.25rem);
      transform: rotate(90deg);
    }
  }
}
.cdk-overlay-dark-backdrop{pointer-events: none; background: #000000 !important; opacity: 0.5 !important;}
// .cdk-overlay-container{pointer-events: unset !important;}
.custom-dialog-container{
  .mat-dialog-container{height: 205px !important; overflow: hidden;}
}
.confirmTitle{font-family: 'Poppins';font-style: normal;font-weight: 700;font-size: 24px;line-height: 32px;text-align: center;color: #33566A;}
.table-striped tbody tr:nth-of-type(odd){background-color: rgba(255,255,255,1) !important;}
.table-striped tbody tr:nth-of-type(even){background-color: rgba(227,236,240, 0.3) !important;}
.custom-modalbox {.mat-dialog-container{overflow: unset;}}
.reportPreview{font-family: 'Poppins',sans-serif; font-style: normal; font-weight: 700; color: #002C45; opacity: 0.8; font-size: 24px;}

#chartjs-tooltip {
  opacity: 1;
  position: absolute;
  background: #66808f;
  color: white;
  border-radius: 4px;
  -webkit-transition: all .1s ease;
  transition: all .1s ease;
  pointer-events: none;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  z-index: 10;

  .tooltip-text {
    color: #e0e6e9;
    font-family: Poppins;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    line-height: 15px;
  }

  .tooltip-sub-text {
    color: #FFFFFF;
    font-family: Poppins;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    line-height: 15px;
  }

  .chartjs-tooltip-key {
    display: inline-block;
    width: 6px;
    height: 6px;
    margin-right: 6px;
    margin-top: 4px;
    border-radius: 50%;
    line-height: 15px;
  }
}
.condtionWrapper .dropdown-menu .showupf4.dropdown-submenu{top:-150px !important;}
.condtionWrapper .dropdown-menu .showupf5.dropdown-submenu{top:-70px !important;}
.condtionWrapper .dropdown-menu .showupf6.dropdown-submenu{top:-50px !important;}
.condtionWrapper .dropdown-menu .showupd4.dropdown-submenu{top:-150px !important;}
.condtionWrapper .dropdown-menu .showupd5.dropdown-submenu{top:-150px !important;}
.condtionWrapper .dropdown-menu .showupd6.dropdown-submenu{top:-70px !important;}
.condtionWrapper .dropdown-menu .showupd7.dropdown-submenu{top:-50px !important;}
.condtionWrapper .dropdown-menu .showupe4.dropdown-submenu{top:-150px !important;}
.condtionWrapper .dropdown-menu .showupe5.dropdown-submenu{top:-150px !important;}
.condtionWrapper .dropdown-menu .showupe6.dropdown-submenu{top:-150px !important;}
.condtionWrapper .dropdown-menu .showupe7.dropdown-submenu{top:-150px !important;}
.condtionWrapper .dropdown-menu .showupe8.dropdown-submenu{top:-70px !important;}
.condtionWrapper .dropdown-menu .showupe9.dropdown-submenu{top:-50px !important;}
.condtionWrapper .dropdown-menu .showupall4.dropdown-submenu{top:-150px !important;}
.condtionWrapper .dropdown-menu .showupall5.dropdown-submenu{top:-150px !important;}
.condtionWrapper .dropdown-menu .showupall6.dropdown-submenu{top:-150px !important;}
.condtionWrapper .dropdown-menu .showupall7.dropdown-submenu{top:-150px !important;}
.condtionWrapper .dropdown-menu .showupall8.dropdown-submenu{top:-150px !important;}
.condtionWrapper .dropdown-menu .showupall9.dropdown-submenu{top:-70px !important;}
.condtionWrapper .dropdown-menu .showupall10.dropdown-submenu{top:-50px !important;}
.report-list thead tr th:last-child .dropdown-menu.w-open.show{left:-165px !important;}


.content-section .newGuPagination .ngx-pagination{text-align: right !important; display: block; float: right; }


body {
  
  a.replygreen {
      color: #6FDDDB;
      &:hover {
          color: #6FDDDB;
      }
  }
}

.optinLink a,.content-box a{
  color: #6FDDDB;
  &:hover {
    color: #6FDDDB;
}
}
